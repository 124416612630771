import React, { useState, useEffect } from "react";
import Button from "../buttons/Button"
import utils from "../../utils/utils";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ManageBtn from "../buttons/ManageBtn";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function ShowCard(props) {

	const [status, setStatus] = useState("");
	const [confirmDeleteModalOpen, toggleConfirmDeleteModalOpen] = useState(false);
	const [prepDelete, setPrepDelete] = useState(false);

	useEffect(() => {
		if (!props.show) return
		const { published, is_finished } = props.show
		if (is_finished) {
			setStatus("Past")
		} else if (published) {
			setStatus("Active")
		} else {
			setStatus("Draft")
		}
	}, [props])

	useEffect(() => {
		
		if (!prepDelete) return
		toggleConfirmDeleteModalOpen(true)
	}, [prepDelete])

	function formatDate(date) {
		date = date.split(" ")
		function getMonthName(monthNumber) {
			const monthNames = [
				"January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
			];
			return monthNames[monthNumber - 1];
		}
		const weekday = date[0]
		const fullDate = date[1].split("/")
		const month = getMonthName(parseInt(fullDate[0]))
		const day = fullDate[1]
		const formattedDate = { weekday, month, day }
		return formattedDate
	}

	return (
		<>
			{/* {numShows > 0 ? */}
			{!props.createNew ?
				<div className="show-card">
					<div className="show-card__header" style={{ backgroundImage: `url(${props.show.production.poster_portrait})` }}>
						<div className="show-card__header__overlay">
							{!props.show.has_orders &&
								<div className="show-card__delete">
									<ManageBtn deleteBtn={true} buttonProps={{ onClick: () => setPrepDelete(true) }}>Delete</ManageBtn>
								</div>
							}
						</div>
					</div>
					<div className="show-card__info">
						<div className="show-card__datetime">
							<div className="show-card__time">{utils.formatDateTime(props.show.date)[0]} at {utils.formatDateTime(props.show.date)[1]}</div>

							<div className="show-card__date--container">
								<div className="show-card__date--header"></div>
								<div className="show-card__date">
									<div className="show-card__date--day">
										{formatDate(utils.formatDateTime(props.show.date)[0]).day}
									</div>
									<div className="show-card__date--text">
										<div className="show-card__date--weekday">{formatDate(utils.formatDateTime(props.show.date)[0]).weekday}</div>
										<div className="show-card__date--month">{formatDate(utils.formatDateTime(props.show.date)[0]).month}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="show-card__info--left">
							<h5 className="show-card__header--title">{props.show.production.name}</h5>
							<p className="show-card__subtext">
								Venue: &nbsp;
								{props.show.venue ?
									<span>{props.show.venue}</span> :
									<span className="show-card__subtext--red">Not set</span>
								}
							</p>

							<p className="show-card__subtext">
								Location: &nbsp;
								{props.show.venue ?
									<span>{props.show.location}</span> :
									<span className="show-card__subtext--red">Not set</span>
								}
							</p>
						</div>

					</div>
					<div className="show-card__manage">
						<Link to={`/showrunner/show?theater=${props.show.theater}&production=${props.show.production.id}&showID=${props.show.id}`}>
							<ManageBtn edit={true}>Edit</ManageBtn>
						</Link>
						<div className={`show-card__status show-card__status--${status.toLowerCase()}`}>{status}</div>
					</div>
				</div>
				:
				<div className="show-card">
					<div className="show-card__add">
						<div className="" onClick={props.addCb}>
							<div className="show-card__add--btn">
								<FontAwesomeIcon icon={faPlus} />
							</div>
						</div>
						<div className="u-mgn-top-sm">
							<h5 className="heading__5 u-align-center show-card__add--text">Create New Event</h5>
							<p className="p__2"></p>
						</div>
					</div>
				</div>
			}
			{prepDelete &&
				<Modal isOpen={confirmDeleteModalOpen} toggle={() => toggleConfirmDeleteModalOpen(!confirmDeleteModalOpen)}>
					<ModalHeader>
						Confirm Deletion
						<div className="modal__close" onClick={() => toggleConfirmDeleteModalOpen(false)}>&#x2715;</div>
					</ModalHeader>
					<ModalBody>
						<div className="u-pad-md">
							<div className="u-mgn-btm-md">

								<p className="p__1">Are you sure you want to delete this show? </p>
								<p className="p__1">This cannot be undone.</p>
							</div>
							<Button buttonProps={{ onClick: () => props.deleteCb(props.show.id) }} className="u-mgn-right-md">Yes, Delete</Button>
							<Button buttonProps={{ onClick: () => {toggleConfirmDeleteModalOpen(false); setPrepDelete(false)} }}>Cancel</Button>
						</div>
					</ModalBody>
				</Modal>
			}
		</>
	)
}
