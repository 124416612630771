import React, { useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import Input from "../components/Input";
import Button from "../components/buttons/Button";
import API from "../utils/API";
import utils from "../utils/utils";
import Navbar from "../components/Navbar";

export default function ResetPassword(props) {

  const { uidb64, token } = useParams();
  const [password, setPassword] = useState("");

  const handleChange = (e) => {
    setPassword(e.target.value);
  }

  const resetPassword = (e) => {
    e.preventDefault();
    const data = {
      password: password
    }
    API.resetPass(uidb64, token, { password })
      .then(res => {
        console.log("reset password res", res)
        utils.sendAlert("Password reset successfully. Logging in...", "success");
        setTimeout(() => {
          API.logIn({ username: res.data.username, password: password })
          .then(res => {
            window.location.href = "/showrunner/home";
          })
        }, 2000);
      })
      .catch(err => {
        console.log("reset password err", err.response)
        if (err.response.data === "Invalid token or user ID.") {
          utils.sendAlert(`Invalid password reset token. It has likely expired.`, "error ");
          utils.sendAlert(`Please request a new password reset link. Using the log in button in the navbar.`, "error ");
        }
      });
  }

  return (
    <div>
      <Navbar />
      <div className="p-reset">


        <div className="p-reset__form">
          <h1>Reset Password</h1>
          <Input inputProps={{ type: "password", name: "password", id: "password", placeholder: "New Password", onChange: handleChange }} className="p-reset__input" />
          <Button buttonProps={{ onClick: resetPassword }}>Reset Password</Button>
        </div>
      </div>
    </div>
  )
}