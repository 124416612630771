import React, { Component } from "react"

export default function ManageBtn({buttonProps, disabled, children, edit, className, deleteBtn}) {
    let buttonClass = "manage-btn"
    if (edit) {
        buttonClass += " manage-btn--edit"
    }

    if (disabled) {
        buttonClass += " button--disabled"
    }

    if (deleteBtn) {
        buttonClass += " manage-btn--delete"
    }

    return (
        <button {...buttonProps} className={`${buttonClass} ${className ? className : ""}`}>
            {children}
        </button>
    )
}
