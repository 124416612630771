import React, {useEffect, useRef} from "react";
import { SlickScanAndroid } from "../assets/apps";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';
import { gsap } from 'gsap';
import { TicketIcon, StripePhoneIcon, SalesIcon, BarsIcon, ScanIcon, SeatsIcon, Particle1 } from "../assets/img/workwithus/svg";

export default function WillCall() {

  const particlesRef1 = useRef([]);
	const particlesRef2 = useRef([]);
	const particlesContainer = useRef(null);

  useEffect(() => {

    const contentRect = particlesContainer.current.getBoundingClientRect();
    let containerWidth = contentRect.width;
    particlesRef1.current.forEach((particle, index) => {
      gsap.fromTo(
        particle,
        {
          y: '100%',
          x: `${Math.random() * containerWidth}px`, // Random initial x position
          opacity: 0.3,
        },
        {
          y: '-1000px',
          opacity: 1,
          duration: 5 + index * 0.5,
          repeat: -1,
          delay: index * 0.2,
          ease: 'none',
        }
      );
    });
    particlesRef2.current.forEach((particle, index) => {
      gsap.fromTo(
        particle,
        {
          y: '100%',
          x: `${Math.random() * containerWidth}px`, // Random initial x position
          opacity: 0.3,
        },
        {
          y: '-1000px',
          opacity: 1,
          duration: 5 + index * 0.5,
          repeat: -1,
          delay: index * 0.2,
          ease: 'none',
        }
      );
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="willcall">
        <div className="willcall__background">
          <div className="particles-container" ref={particlesContainer}>

            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className="particle"
                ref={(el) => (particlesRef2.current[index] = el)}
              />
            ))}
            {[...Array(8)].map((_, index) => (
              <Particle1
                key={index}
                className="particle1"
                ref={(el) => (particlesRef1.current[index] = el)}
              />
            ))}
          </div>
        </div>
        <h1 className="">Download the SlickScan app.</h1>
        <p className="p__2 u-mgn-btm-sm">
          Once you open the app, just point the camera on the ticket barcode to validate!
        </p>

        <div className="willcall__downloads">
          <div className="willcall__downloads-item">
            <FontAwesomeIcon icon={faApple} />
            <p>Coming to iOS soon!</p>
          </div>
          <a className="u-link--simple" href={SlickScanAndroid} download>
            <div className="willcall__downloads-item">
              <FontAwesomeIcon icon={faAndroid} />
              <p>Download Now</p>
            </div>
          </a>
        </div>
      </div>
      <div className="willcall__footer">
        <Footer />
      </div>
    </>
  )
}