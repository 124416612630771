import React, { useState, useEffect } from "react";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryTheme, VictoryVoronoiContainer, VictoryBar } from 'victory';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useAuth } from '../../utils/authContext';
import API from "../../utils/API";
import ClipLoader from "react-spinners/ClipLoader";
import utils from "../../utils/utils";
import { Table, TableBody, TableHead, TableRow, TH, TD } from "../../components/Table";
import Button from "../../components/buttons/Button";

const MAX_TICKS = 7;

const flashyTheme = {
  axis: {
    style: {
      axis: {
        stroke: "white", // Color of the axis line
        strokeWidth: 2,
      },
      axisLabel: {
        fontSize: 20,
        padding: 30,
        fill: "#0029FF", // Color of the axis label
      },
      grid: {
        stroke: "none", // Remove grid lines
      },
      ticks: {
        stroke: "transparent",
        size: 5,
        strokeWidth: 2,
      },
      tickLabels: {
        fontSize: 15,
        padding: 5,
        fill: "#A2A2A2", // Color of the tick labels
      },
    },
  },
  line: {
    style: {
      data: {
        strokeWidth: 3,
        strokeLinecap: "round",
      },
      labels: {
        fontSize: 15,
        fill: "#0029FF",
      },
    },
  },

};

export default function Slickfilliates() {
  const { auth, showrunner, isAuthenticated, loading } = useAuth();

  const [startDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);
  const [allTime, setAllTime] = useState(true);
  const [revenueChart, setRevenueChart] = useState([]);
  const [revenueTotal, setRevenueTotal] = useState(0);
  const [revenueLabels, setRevenueLabels] = useState([]);
  const [ticketLabels, setTicketLabels] = useState([]);
  const [ticketChart, setTicketChart] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      window.location.href = "/"
    }
    if (!isAuthenticated || !showrunner) return;

    fetchData();
  }, [isAuthenticated, startDate, endDate, allTime]);

  const fetchData = () => {
    setLoadingData(true);
    const filters = {};

    if (startDate && endDate && !allTime) {
      filters["start_date"] = startDate.format('YYYY-MM-DD');
      filters["end_date"] = endDate.format('YYYY-MM-DD');
    }

    API.getReferralCharts(showrunner.id, filters)
      .then(res => {
        const revenueChartData = res.data.financial_chart.map(item => ({
          x: new Date(item.date_created__date),
          y: item.total_revenue
        }));

        const ticketChartData = res.data.ticket_chart.map(item => ({
          x: new Date(item.order__date_created__date),
          y: item.tickets_sold
        }));

        const { aggregatedData: aggregatedRevenueData, labels: newRevenueLabels } = aggregateData(revenueChartData, startDate, endDate);
        const { aggregatedData: aggregatedTicketData, labels: newTicketLabels } = aggregateData(ticketChartData, startDate, endDate);
        setRevenueChart(aggregatedRevenueData);
        setTicketChart(aggregatedTicketData);
        setRevenueTotal(res.data.total_revenue);
        setTicketTotal(res.data.total_tickets);
        setLoadingData(false);
        setRevenueLabels(newRevenueLabels);
        setTicketLabels(newTicketLabels);
      });

    API.getReferrals(showrunner.id)
      .then(res => {
        setReferrals(res.data);
        setLoadingData(false);
      });
  };

  const aggregateData = (data, startDate, endDate) => {
    const timeSpanDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    let interval, labelFormat;

    if (timeSpanDays <= 7) {
      // Daily intervals
      interval = 'daily';
      labelFormat = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
    } else if (timeSpanDays <= 30) {
      // Weekly intervals
      interval = 'weekly';
      labelFormat = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
    } else if (timeSpanDays <= 365) {
      // Monthly intervals
      interval = 'monthly';
      labelFormat = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
    } else {
      // Quarterly intervals
      interval = 'quarterly';
      labelFormat = (date) => `Q${Math.floor(date.getMonth() / 3) + 1} ${date.getFullYear().toString().slice(-2)}`;
    }

    const aggregatedData = aggregateByInterval(data, interval);
    const labels = aggregatedData.map((item) => labelFormat(new Date(item.x)));

    return { aggregatedData, labels };
  }

  const aggregateByInterval = (data, interval) => {
    if (data.length <= MAX_TICKS) {
      return data;
    }

    const intervalSize = Math.ceil(data.length / MAX_TICKS);
    let aggregatedData = [];

    for (let i = 0; i < data.length; i += intervalSize) {
      const chunk = data.slice(i, i + intervalSize);
      const totalValue = chunk.reduce((sum, item) => sum + item.y, 0);
      const averageDate = new Date(chunk.reduce((sum, item) => sum + item.x.getTime(), 0) / chunk.length);
      aggregatedData.push({ x: averageDate, y: totalValue });
    }

    return aggregatedData;
  };

  return (
    <>
      {!loadingData ?
        <div className="showrunner__finances">
          <h1>Slickfilliates</h1>
          <div className="showrunner__filters u-flex-justify-around">
            <div className="showrunner__filters__section showrunner__filters__section--f2">
              {allTime ?
                <div className="select u-hover-pointer u-flex u-flex-align-center" onClick={() => setAllTime(false)}> 
                  <span>All Time</span>
                  <span className="caret"></span>
                </div>
                :
                <div className="date-range">
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="start_date_id"
                    endDate={endDate}
                    endDateId="end_date_id"
                    onDatesChange={({ startDate, endDate }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    displayFormat="MM/DD/YYYY"
                  />
                  <Button buttonProps={{ onClick: () => setAllTime(true) }} sm={true} className="showrunner__filters--reset">Reset</Button>
                </div>
              }
            </div>
          </div>
  
          <div className="dash-home__charts">
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Referral Revenue:</p>
              <p className="dash-home__chart__total">{utils.formatFinancial(revenueTotal)}</p>
              {revenueChart.length > 0 && (
                <VictoryChart
                  theme={flashyTheme}
                  scale={{ x: "time" }}
                  containerComponent={<VictoryVoronoiContainer />}
                >
                  <VictoryAxis
                    tickValues={revenueChart.map(d => d.x)}
                    tickFormat={(x, index) => revenueLabels[index]}
                  />
                  <VictoryAxis
                    dependentAxis
                    tickFormat={(y) => `$${y}`}
                  />
                  <VictoryLine
                    data={revenueChart}
                    x="x"
                    y="y"
                    labels={({ datum }) => `$${datum.y.toFixed(2)}`}
                    labelComponent={<VictoryTooltip />}
                  />
                </VictoryChart>
              )}
            </div>
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Tickets Sold:</p>
              <p className="dash-home__chart__total">{ticketTotal}</p>
              {ticketChart.length > 0 && (
                <VictoryChart
                  theme={flashyTheme}
                  scale={{ x: "time" }}
                  domainPadding={{ x: 20 }}
                  containerComponent={<VictoryVoronoiContainer />}
                >
                  <VictoryAxis
                    tickValues={ticketChart.map(d => d.x)}
                    tickFormat={(x, index) => ticketLabels[index]}
                  />
                  <VictoryAxis
                    dependentAxis
                    tickFormat={(y) => `${y}`}
                  />
                  <VictoryBar
                    data={ticketChart}
                    x="x"
                    y="y"
                    labels={({ datum }) => `${datum.y}`}
                    labelComponent={<VictoryTooltip />}
                  />
                </VictoryChart>
              )}
            </div>
          </div>
  
          <h2>Referrals</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TH>Referred User</TH>
                <TH>Total Revenue</TH>
              </TableRow>
            </TableHead>
            <TableBody>
              {referrals.map(referral => (
                <TableRow key={referral.referred_user.id}>
                  <TD>{referral.referred_user.first_name} {referral.referred_user.last_name}</TD>
                  <TD>{utils.formatFinancial(referral.total_revenue)}</TD>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        :
        <div className="u-flex-all-center u-full-width">
          <ClipLoader color="#0029FF" loading={true} css={""} size={150} speedMultiplier={.3} />
        </div>
      }
    </>
  );
}