import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import API from "../utils/API";
import utils from "../utils/utils";


export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [paid, setPaid] = useState(false);
  const [hasReferral, setHasReferral] = useState(false);
  const [secureToken, setSecureToken] = useState(false);

  useEffect(() => {
    // Create PaymentIntent as soon as component loads

    let stripeTotal = props.customerTotal
    stripeTotal = stripeTotal * 100
    stripeTotal = Math.round(stripeTotal * 100) / 100
    if (stripeTotal.toString().includes(".")) {
      stripeTotal = parseInt(stripeTotal.toString().split(".")[0])

    }
    const showID = props.showID.replace(/\D+/g, '');
    const req = { total: stripeTotal, fee: (props.stFeeTotal + props.ccFee), stripe_connect_id: props.connectAcct, showID }
    API.postPayment(req)
      .then(res => {
        setClientSecret(res.data.clientSecret);
        console.log("postPayment res", res)
        if (res.data.has_referral) {
          setHasReferral(true)
          setSecureToken(res.data.secureToken)
        }
      })
  }, [props.customerTotal]);

  useEffect(() => {
    if (!stripe || !clientSecret) return;

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: Math.round(props.customerTotal * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr);
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }, [clientSecret]);

    // Listener for payment method generated by Payment Request Button
    pr.on('paymentmethod', async (event) => {
      setProcessing(true);
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: event.paymentMethod.id,
      }, {
        handleActions: false
      });

      if (error) {
        console.error("Payment failed", error);
        setError(`Payment failed: ${error.message}`);
        setProcessing(false);
        event.complete('fail');
      } else {
        setSucceeded(true);
        setError(null);
        setProcessing(false);
        event.complete('success');
        // Additional steps here if needed
      }
    });
  }, [stripe, clientSecret, props.customerTotal]);



  const cardStyle = {
    style: {
      base: {
        fontSmoothing: "antialiased",
        "::placeholder": {
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function formatSelectedCategories(obj) {
    const formatted = {
      categories: [],
      subcategories: []
    }
    for (let key in obj) {
      const category = obj[key]
      let subcategories = []
      if (category.subcategories.length < 1) {
        formatted.categories.push(category)
      } else {
        for (let key in category.subcategories) {
          const subcategory = category.subcategories[key]
          subcategories.push(subcategory)
        }
      }
    }
    return formatted
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const { deliveryMethod, checkoutInfo } = props
    // return
    if (checkoutInfo.fullName.length < 1 || checkoutInfo.email.length < 1) {
      utils.sendAlert("Please fill out all fields", "error")
      return
    }

    if (!validateEmail(checkoutInfo.email)) {
      utils.sendAlert("Please enter a valid email address", "error")
      return
    }

    if (deliveryMethod === "email") {

      if (checkoutInfo.email !== checkoutInfo.confirmEmail || checkoutInfo.email === "") {
        utils.sendAlert("Emails must match", "error")
        return
      }
      if (!checkoutInfo.email) {
        utils.sendAlert("Please enter a valid email address before purchasing a ticket", "error")
        return
      }
    } else if (deliveryMethod === "mail") {

      if (checkoutInfo.mailAddress.length < 1 || checkoutInfo.zipCode.length < 1 || checkoutInfo.city.length < 1) {
        utils.sendAlert("Please fill out all fields", "error")
        return
      }
    } else if (deliveryMethod === "willCall") {

    }
    let tickets
    let action
    if (!props.freeSeating) {
      action = "checkTaken"
      tickets = Object.keys(props.seats).join(",")
      if (Object.keys(props.seats).length > 9) {
        utils.sendAlert("You can only purchase 9 tickets at a time. Please try again", "error")
        return
      }
    } else {
      action = "checkAvailable"
      // tickets = formatSelectedCategories(props.seats)
      tickets = JSON.stringify(props.ticketCategories)
    }
    // return
    const showID = props.showID.replace(/\D+/g, '');
    API.checkTicketStatus(tickets, showID, action)
      .then(async res => {

        const { available, too_many } = res.data
        if (!available) {
          utils.sendAlert("Sorry, looks like one or more of your tickets was just purchased. Please select different seats.", "error")
          props.closeModal()
          props.checkoutFail(showID)
          return
        }

        if (too_many) {
          utils.sendAlert("You can only purchase 9 tickets at a time. Please try again", "error")
          return
        }

        setProcessing(true);


        // TICKET DELIVERY
        const ticketsArray = Object.keys(props.seats)
        let stripeTotal = props.total

        if (props.deliveryMethod === "mail") {
          stripeTotal += 1
        }

        stripeTotal = stripeTotal * 100
        const req = { deliveryMethod, checkoutInfo, seats: props.seats, showID: showID, total: props.emailTotal * 100, stFeeTotal: props.stFeeTotal, ticketsTotal: props.ticketsTotal, tickets: ticketsArray.join(","), customerTotal: props.customerTotal.toFixed(2), ccFee: props.ccFee, ticketCategories: props.ticketCategories, hasReferral }
        console.log("REQ", req)
        // return
        // PAYMENT PROCESSING
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              email: props.email,
            }
          }
        });
        if (payload.error) {
          setError(`Payment failed ${payload.error.message}`);
          utils.sendAlert("There was an error, please try again", "error")
          setProcessing(false);
        } else if (payload.paymentIntent.payment_method.length > 1 && payload.paymentIntent.status === "succeeded") {
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          // req.total = netTotal
          props.closeModal();
          utils.sendAlert("Please hang tight as we process your order.", "success")

          req["payment_id"] = payload.paymentIntent.id
          let freeSeating = true
          if (!props.freeSeating) {
            freeSeating = false
          }
          req["freeSeating"] = freeSeating

          API.sendTickets(req)
            .then(res => {

              // props.paymentFinishedCB();
              setPaid(true);
              if (res.data.message.startsWith("Failed")) {
                utils.sendAlert("There was an issue sending your tickets. Please contact us at info@slick-tix.org detailing the tickets you purchased", "error")
                props.checkoutFail()
                return
              } else {
                utils.sendAlert("Payment succeeded! Please check your email inbox. Enjoy the show!", "success")
                for (let key in props.seats) {
                  const seat = props.seats[key]
                  if (seat.element) {
                    delete seat.element
                  }
                }
              }
              if (hasReferral) {

                const referralReq = {
                  payment_intent_id: payload.paymentIntent.id,
                  secure_token: secureToken,
                  showID,
                  total: props.ticketsTotal
                }
                console.log("referralReq", referralReq)
                API.sendReferralTake(referralReq)
                  .then(res2 => {
                    console.log("referral Response", res2)
                  })
                  .catch(err => {
                    console.log("ERROR", err)
                  })
              }
            })
            .catch(err => {
              console.log("ERROR", err)
            })
        } else {
          utils.sendAlert("Something went wrong. Your payment was not processed. If the problem reoccurs, please reach us with the contact info shown in the footer.", "error")
        }
        // }).catch(err => {
        //   utils.sendAlert("Something went wrong. Your payment was not processed. If the problem reoccurs, please reach us with the contact info shown in the footer.", "error")
        // })

      })

  };


  return (
    <form id="payment-form" className="event__payment-form" onSubmit={handleSubmit}>
      {/* {showButton && paymentRequest && (
    <PaymentRequestButtonElement options={{ paymentRequest }} className="payment-request-button" />
)} */}
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />

      <p className="ch-p3">Secured by <a target="_blank" href="https://stripe.com/">Stripe</a></p>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          <p className="p__1 p__1--ko">
            {error}
          </p>
        </div>
      )}
      <div className="u-flex-all-center u-flex-column">

        <button
          disabled={processing || disabled || succeeded}
          id="submit"
          className="u-mgn-top-md u-full-width"
          style={{ border: "none" }}
        >
          {/* <span id="button-text" > */}
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            <div className={` button theater__cart__btn ${paid ? "button--disabled" : ""}`}>
              Finalize payment
            </div>
          )}
          {/* </span> */}
        </button>
        {/* Show a success message upon completion */}
        <p className={succeeded ? "p__2" : "p__2 hidden"}>
          Payment succeeded! Please check your email inbox. Enjoy the show!
        </p>
      </div>
    </form>
  );
}