import { useState, useEffect } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import API from "../../utils/API";

export const useStripeConnect = (connectedAccountId) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState();

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = () => {
        return API.stripeConnectSession({account: connectedAccountId})
        .then( res => {
          if (!res.data.client_secret) {
              throw new Error("Client secret not found");
            }
          return res.data.client_secret
        })
      };
      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_KEY,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        })
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};

export default useStripeConnect;