import React, { useEffect, useState } from "react";
import { Table, TableBody, TableHead, TableRow, TH, TD } from "../../../components/Table";
import Input from "../../../components/Input";
import API from "../../../utils/API";
import utils from "../../../utils/utils";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from "react-spinners";
import Button from "../../../components/buttons/Button"; // Ensure this import is correct

export default function Clients() {
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        API.getClients()
            .then(res => {
                setClients(res.data);
                setFilteredClients(res.data);
            })
            .catch(error => {
                console.error("Error fetching clients", error);
            });
    }, []);

    useEffect(() => { 
        setFilteredClients(clients);
    }, [clients]);

    function filterClients(e) {
        const { value } = e.target;
        const filtered = clients.filter(client => {
            const usernameMatch = client.username && client.username.toLowerCase().includes(value.toLowerCase());
            const emailMatch = client.email && client.email.toLowerCase().includes(value.toLowerCase());
            const firstNameMatch = client.first_name && client.first_name.toLowerCase().includes(value.toLowerCase());
            const lastNameMatch = client.last_name && client.last_name.toLowerCase().includes(value.toLowerCase());
            return usernameMatch || emailMatch || firstNameMatch || lastNameMatch;
        });
        setFilteredClients(value === "" ? clients : filtered);
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        utils.sendAlert("Copied to clipboard", "success");
    };

    const checkStripeStatus = (clientId, stripeConnectId) => {
        setLoading(clientId); // Set loading to the ID of the current client to show the loader only for that row

        API.getStripeConnectDash(stripeConnectId)
            .then(res => {
                const { link, error, onboarding } = res.data;
                if (onboarding.status === "complete") {
                    console.log("Stripe onboardng", onboarding); // Debug log
                    utils.sendAlert("Stripe setup completed", "success");

                    // Update the stripe_complete flag locally
                    setClients(prevClients => {
                        // Create a new array with updated clients
                        const updatedClients = prevClients.map(client => {
                            if (client.id === clientId) {
                                console.log(`Updating client ${client.id} to stripe_complete: true`); // Debug log
                                return { ...client, stripe_complete: true };
                            }
                            return client;
                        });

                        // Return a new reference to trigger a re-render
                        return [...updatedClients];
                    });

                    // API call to update the Showrunner instance
                    API.updateProfile(clientId, { stripe_complete: true })
                        .then(response => {
                            console.log('Showrunner instance updated successfully', response.data);
                        })
                        .catch(error => {
                            console.error('Error updating Showrunner instance:', error);
                            utils.sendAlert('Error updating Stripe status in the database', 'error');
                        });
                } else if (error) {
                    utils.sendAlert("Stripe setup still incomplete", "error");
                }
                setLoading(null); // Reset loading state
            })
            .catch(error => {
                if (error.response) {
                    console.error("Error checking Stripe status", error.response.data.error);
                    utils.sendAlert(error.response.data.error, "error");
                } else {
                    console.error("Error checking Stripe status", error.response.data.error);
                    utils.sendAlert("Error checking Stripe status", "error");
                }
                setLoading(null);
            });
    };

    return (
        <div className="clients__container">
            <div className="clients__header">
                <h3 className="heading__3">Showrunners</h3>
            </div>
            <div className="clients__filters">
                <Input
                    className="clients__filters--search"
                    inputProps={{ placeholder: "Search email, first name, or last name", onChange: filterClients }}
                />
            </div>

            <Table>
                <TableHead>
                    <TableRow>
                        <TH>Email</TH>
                        <TH>First Name</TH>
                        <TH>Last Name</TH>
                        <TH>Company</TH>
                        <TH>Stripe</TH>
                        <TH>Source</TH> {/* New column for the source */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredClients.map(client => (
                        <TableRow key={client.id}>
                            <TD>
                                <div className="u-flex u-flex-align-center">
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        className="u-hover-pointer u-mgn-left-sm"
                                        onClick={() => handleCopy(client.email)}
                                    />
                                    <div className="u-mgn-left-sm ellipsis" data-tip={client.email}>
                                        {client.email}
                                    </div>
                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                </div>
                            </TD>
                            <TD>{client.first_name}</TD>
                            <TD>{client.last_name}</TD>
                            <TD>{client.company}</TD>
                            <TD>
                                {loading === client.id ? (
                                    <ClipLoader color="#0029FF" size={20} />
                                ) : client.stripe_complete ? (
                                    "Complete"
                                ) : client.stripe_connect_id ? (
                                    <Button sm={true} buttonProps={{ onClick: () => checkStripeStatus(client.id, client.stripe_connect_id) }}>
                                        Incomplete
                                    </Button>
                                ) : (
                                    "Not started"
                                )}
                            </TD>
                            <TD>{client.hdyh || '?'}</TD> {/* Display the source or '?' */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}