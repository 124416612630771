import React, { useEffect, useState } from "react";
import { Table, TableBody, TableHead, TableRow, TH, TD } from "../../../components/Table";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../../components/buttons/Button";
import Input from "../../../components/Input";
import API from "../../../utils/API";
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RadioButton from "../../../components/RadioButton";
import ClipLoader from "react-spinners/ClipLoader";
import utils from "../../../utils/utils";
// import FloorPlan from "../../Gratis/FloorPlan";
import FloorPlan from "../../newfloorplan/Floorplan";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../utils/authContext";

export default function TicketCategory(props) {
  const location = useLocation();
  const { showrunner } = useAuth();

  const initSeatState = {
    selectedSeats: {},
    seatClickTracker: {},
    seatIndex: {}
  }
  const [show, setShow] = useState({})
  const [ticketsToAdd, setTicketsToAdd] = useState({})
  const [subCategory, setSubCategory] = useState({})
  const [activeCategory, setActiveCategory] = useState(0)
  const [activeCategoryEdit, setActiveCategoryEdit] = useState({})
  const [activeSubCategoryEdit, setActiveSubCategoryEdit] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [category, setCategory] = useState(false)
  const [categoryEdit, setCategoryEdit] = useState({})
  const [canPublish, setCanPublish] = useState(true)
  const [prod, setProd] = useState({})
  const [publishChecklist, setPublishChecklist] = useState({})
  const [floorplan, setFloorplan] = useState([])
  const [seatState, setSeatState] = useState(initSeatState)
  const [ticketCategories, setTicketCategories] = useState({ categories: [], totals: {} })
  const [newCategory, setNewCategory] = useState({})
  const [total, setTotal] = useState(0)
  const [showID, setShowID] = useState(0)
  const [theater, setTheater] = useState({})
  const [checkoutInfo, setCheckoutInfo] = useState({})

  const [subCategoryModalOpen, toggleSubCategoryModal] = useState(false)
  const [subCategoryEditModalOpen, toggleSubCategoryEditModal] = useState(false)
  const [sendTicketModalOpen, toggleSendTicketModal] = useState(false)
  const [categoryModalOpen, toggleCategoryModal] = useState(false)
  const [confirmCategoryDelete, toggleConfirmCategoryDelete] = useState(false)
  const [confirmSubCategoryDelete, toggleConfirmSubCategoryDelete] = useState(false)
  const [ticketCategoriesModalOpen, toggleTicketCategoriesModal] = useState(false)
  const [editTicketModalOpen, toggleEditTicketModal] = useState(false)

  useEffect(() => {
    if (!showrunner) return;
    reset()
  }, [showrunner])

  useEffect(() => {
    console.log("activeCategoryEdit", activeCategoryEdit)
  }, [activeCategoryEdit])

  function reset() {
    const params = new URLSearchParams(location.search);
    const paramsShowID = params.get("showID");
    if (paramsShowID) {
      setShowID(paramsShowID);
    }
    API.getShow(paramsShowID)
      .then(res => {
        console.log("Show", res.data)
        setShow(res.data)
      })


    API.getShowManage(paramsShowID, showrunner.id)
      .then(res => {
        const { can_publish_checklist } = res.data
        setPublishChecklist(can_publish_checklist)
        for (let key in can_publish_checklist) {
          if (!can_publish_checklist[key]) {
            setCanPublish(false)
            return
          }
        }
      })

    let seatIndex
    API.getEventTix(paramsShowID)
      .then(res => {
        console.log("Tix", res.data)
        if (res.data.theater) {
          setTheater(res.data.theater)
          setFloorplan(res.data.theater.floorplan.sectors)
        }
        resetTicketCategories(paramsShowID)

        setProd(res.data.prod)
        setSeatState(prevState => ({ ...prevState, seatIndex }));

      })
  }

  function resetTicketCategories(id) {
    API.getTicketCategories(id)
      .then(res => {
        setTicketCategories(res.data)
      })
  }

  function addNewTicketCategory(e) {
    e.preventDefault();
    const req = newCategory;
    req["showID"] = showID;
    API.addTicketCategory(req)
      .then(res => {
        if (floorplan.length < 1) {
          const addTixReq = { showID, numTix: newCategory.numTix, category: res.data.id };
          API.addTickets(addTixReq)
            .then(res2 => {
              utils.sendAlert("New ticket category added", "success");
              resetTicketCategories(showID);
              toggleTicketCategoriesModal(false);
              console.log("res2", res2)
              props.reset(showID, showrunner.id);
            })
        } else {
          utils.sendAlert("New ticket category added", "success");
          resetTicketCategories(showID);
          toggleTicketCategoriesModal(false);
          props.reset(showID, showrunner.id);
        }
      })

  }

  function addSubCategory(e) {
    e.preventDefault()
    const req = subCategory
    req["category"] = activeCategory
    API.addSubcategory(req)
      .then(res => {
        // props.reset(props.state.showID);
        reset();
        utils.sendAlert("Subcategory Added", "success");
        toggleSubCategoryModal(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function handleSubCategoryChange(e) {
    const { name, value } = e.target
    setSubCategory({ ...subCategory, [name]: value })
  }

  function seatClick(data, cb) {
    const { selectedSeats, seatClickTracker } = seatState
    console.log("SeatState", seatState)
    const { seat, price } = data
    console.log("seat", seat)
    if (selectedSeats[seat]) {
      delete selectedSeats[seat]
      setTotal(oldNum => oldNum - price)
    } else {
      selectedSeats[seat] = data
      setTotal(oldNum => oldNum + price)
    }
    seatClickTracker[seat] = cb

    setSeatState(prevState => ({ ...prevState, selectedSeats, seatClickTracker }));
  }


  function handleSendTicket(e) {
    const { name, value } = e.target
    setCheckoutInfo({ ...checkoutInfo, [name]: value })
  }

  function handleNewTicketCategoryData(e) {
    setNewCategory({ ...newCategory, [e.target.name]: e.target.value });
  }

  function handleCategoryEdit(e) {
    const { name, value } = e.target
    setActiveCategoryEdit({ ...activeCategoryEdit, [name]: value })
  }

  function handleSubCategoryEdit(e) {
    const { name, value } = e.target
    setActiveSubCategoryEdit({ ...activeSubCategoryEdit, [name]: value })
  }


  function updateCategory() {

    API.editCategory(activeCategoryEdit)
      .then(res => {
        utils.sendAlert("Category Updated", "success")
        toggleCategoryModal(false);
        reset();

        setActiveCategoryEdit({})
      })
      .catch(err => {
        const { error } = err.response.data
        utils.sendAlert(error, "error")
      })
  }

  function updateSubCategory() {

    API.editSubcategory(activeSubCategoryEdit)
      .then(res => {
        utils.sendAlert("Subcategory Updated", "success")
        toggleSubCategoryEditModal(false);
        // props.reset(props.state.showID);
        reset();
        setActiveSubCategoryEdit({})
      })
  }

  function convertTicketCategories(array) {
    return array.reduce((acc, category) => {
      const { id, name, price, discount, show, total_tickets, tickets_taken, tickets_available, subcategories, numSelected } = category;

      acc[name] = {
        id,
        name,
        price,
        discount,
        show,
        total_tickets,
        tickets_taken,
        tickets_available,
        subcategories: subcategories || {},
        numSelected: numSelected || 0,
      };

      // Convert subcategories array to object if it exists
      if (Array.isArray(subcategories)) {
        acc[name].subcategories = subcategories.reduce((subAcc, subcategory) => {
          subAcc[subcategory.name] = subcategory;
          return subAcc;
        }, {});
      }

      return acc;
    }, {});
  }

  function submitGratis() {
    if (!showID) return;
    const formattedCategories = convertTicketCategories(ticketCategories.categories);
    const req = {
      checkoutInfo,
      seats: seatState.selectedSeats,
      showID,
      freeSeating: floorplan.length > 0 ? false : true,
      ticketCategories: formattedCategories,
    }
    Object.keys(req.ticketCategories).forEach(key => {
      const category = req.ticketCategories[key];
      if (category.id === activeCategory) {
        category.numSelected = parseInt(checkoutInfo.numTickets);
      }
    });

    console.log("Req", req)
    // return
    API.submitGratis(req)
      .then(res => {
        setIsLoading(false)
        toggleSendTicketModal(false)
        setSeatState(initSeatState)
        if (res.data.message && res.data.message === "error") {
          utils.sendAlert("There was an error sending your tickets. ", "error")
        } else {
          utils.sendAlert("Tickets sent! ", "success")
          reset();
        }
      })
      .catch(err => {
        utils.sendAlert("Tickets failed to send. Please try again.", "error")
      })
  }



  return (
    <>

      <div className="ticket-manager u-mgn-top-md">
        <h3 className="ticker-category__heading heading__3">{prod.name}</h3>
        <div className="ticket-manager__header">
          <div className="ticket-manager__btns">

            {/* {floorplan.length > 0 &&
              <Button disabled={Object.keys(seatState.selectedSeats).length > 0 ? false : true} buttonProps={{ onClick: () => toggleEditTicketModal(true) }} alt={true} sm={true} className="u-mgn-right-sm">Edit</Button>} */}
            {floorplan.length > 0 ?
              <Button buttonProps={{ onClick: () => { if (Object.keys(seatState.selectedSeats).length < 1) return; toggleSendTicketModal(true); } }} sm={true} className="u-mgn-right-sm" disabled={Object.keys(seatState.selectedSeats).length < 1 ? true : false} alt={true}>Send Tickets</Button>
              : <Button buttonProps={{ onClick: () => { if (!category) return; toggleSendTicketModal(true); } }} sm={true} className="u-mgn-right-sm" disabled={!category ? true : false} alt={true}>Send Tickets</Button>
            }
            <Button buttonProps={{ onClick: () => toggleTicketCategoriesModal(true) }} className="u-mgn-right-sm" alt={true} sm={true}>+ Add Category</Button>
            <Button buttonProps={{ onClick: () => { if (!category) return; toggleSubCategoryModal(true); } }} sm={true} disabled={!category ? true : false} alt={true} className="u-mgn-right-sm">+ Add Subcategory</Button>
          </div>
        </div>
        <div>


          <Table>
            <TableHead>
              <TableRow className="ticket-manager__row">
                <TH></TH>
                <TH>Ticket Category</TH>
                <TH>Total Tickets</TH>
                <TH>Tickets Available</TH>
                <TH>Tickets Sold</TH>
                <TH>Ticket Price</TH>
                <TH></TH>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="ticket-manager__row">
                <TD></TD>
                <TD>Total</TD>
                <TD>{ticketCategories.totals.total_tickets ? ticketCategories.totals.total_tickets : 0}</TD>
                <TD>{ticketCategories.totals.total_tickets ? (ticketCategories.totals.total_tickets - ticketCategories.totals.tickets_taken) : 0}</TD>
                <TD>{ticketCategories.totals.total_tickets ? ticketCategories.totals.tickets_taken : 0}</TD>
                <TD></TD>
                <TD></TD>
              </TableRow>
              {ticketCategories.categories.map(category => (
                <>
                  <TableRow key={category.id} className="ticket-manager__row">
                    <TD>
                      <RadioButton name={category.name} onClick={() => { setCategory(category); setActiveCategory(category.id); }} />
                    </TD>
                    <TD className="u-font-bold">
                      {category.name}
                    </TD>
                    <TD>{floorplan.length < 1 ? category.total_tickets : null}</TD>
                    <TD>{floorplan.length < 1 ? category.total_tickets - category.tickets_taken : null}</TD>
                    <TD>{category.tickets_taken}</TD>
                    <TD>
                      {category.subcategories.length > 0 ? "" : (
                        <>
                          ${category.price}  {/* <FontAwesomeIcon icon={faEdit} /> */}
                        </>
                      )}
                    </TD>
                    <TD>
                      <div className="ticket-manager__icons">
                        <div className="ticket-manager__icon" onClick={() => { toggleCategoryModal(true); setActiveCategoryEdit(category); }}>
                          <FontAwesomeIcon icon={faEdit} />
                        </div>
                        <div className="ticket-manager__icon" onClick={() => { toggleConfirmCategoryDelete(true); setActiveCategoryEdit(category) }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </div>
                    </TD>

                  </TableRow>
                  {category.subcategories && category.subcategories.map((sub) => (
                    <TableRow key={sub.id} className="c-table__row--sub ticket-manager__row">
                      <TD ></TD>
                      <TD style={{ paddingLeft: '20px' }}>{sub.name}</TD>
                      <TD>{!floorplan ? sub.total_tickets : null}</TD>
                      <TD>{ }</TD>
                      {/* A tickets sold value would be useful here */}
                      <TD></TD>
                      <TD>${sub.price}</TD>
                      <TD>
                        <div className="ticket-manager__icons">
                          <div className="ticket-manager__icon" onClick={() => { toggleSubCategoryEditModal(true); setActiveSubCategoryEdit(sub); }}>
                            <FontAwesomeIcon icon={faEdit} />
                          </div>
                          <div className="ticket-manager__icon" onClick={() => { toggleCategoryModal(true); setActiveSubCategoryEdit(sub); }}>
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </TD>
                    </TableRow>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </div>
        {floorplan.length > 0 ?
          <div className="showrunner__floorplan">
            <p className="showrunner__floorplan__selected">  Selected: {
              seatState.selectedSeats
                ? Object.keys(seatState.selectedSeats).join(", ")
                : "No seats selected"
            }</p>
            <div className="u-flex u-flex-column u-flex-align-center u-mgn-top-md palace">
              <div className="seat__stage" style={{ width: "80%" }}>Stage</div>
              {/* <FloorPlan seats={floorplan} /> */}
              <FloorPlan eventId={showID} seatClick={seatClick} selectedSeats={seatState.selectedSeats
              } theater={theater} isEventManager={true} />
            </div>
          </div> : null}



        <Modal isOpen={subCategoryModalOpen} toggle={() => toggleSubCategoryModal(!subCategoryModalOpen)}>
          <ModalHeader>
            Add a new Subcategory
            <div className="modal__close" onClick={() => toggleSubCategoryModal(false)}>&#x2715;</div>
          </ModalHeader>
          <ModalBody>
            <div className="u-pad-md">
              <div className="u-mgn-btm-sm">
                <p className="p__1">Set a new ticket subcategory</p>
                <form>
                  <Input inputProps={{ type: "text", placeholder: "Subcategory Name", name: "name", onChange: handleSubCategoryChange }} />
                  <Input inputProps={{ type: "float", placeholder: "Ticket Price", name: "price", onChange: handleSubCategoryChange }} />
                  <Button buttonProps={{ onClick: addSubCategory, type: "button" }}>Add</Button>
                </form>
              </div>

            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={subCategoryEditModalOpen} toggle={() => toggleSubCategoryEditModal(!subCategoryEditModalOpen)}>
          <ModalHeader>
            Edit Subcategory
            <div className="modal__close" onClick={() => toggleSubCategoryEditModal(false)}>&#x2715;</div>
          </ModalHeader>
          <ModalBody>
            <div className="u-pad-md">
              <div className="u-mgn-btm-sm">
                {/* <p className="p__1">Set a new ticket subcategory</p> */}
                <form>
                  <div className="u-mgn-btm-sm">
                    <p className="p__1">SubCategory Name</p>
                    <Input className={"p__1"} inputProps={{ name: "name", onChange: handleSubCategoryEdit, placeholder: activeSubCategoryEdit.name }} />
                  </div>
                  <div className="u-mgn-btm-sm">
                    <p className="p__1">Price</p>
                    <Input className={"p__1"} inputProps={{ name: "price", onChange: handleSubCategoryEdit, placeholder: activeSubCategoryEdit.price }} price={true} />
                  </div>

                  <Button buttonProps={{ onClick: updateSubCategory, type: "button" }}>Update</Button>
                </form>
              </div>

            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={sendTicketModalOpen} toggle={() => toggleSendTicketModal(!sendTicketModalOpen)}>
          <ModalHeader>
            Send Free Tickets
            <div className="modal__close" onClick={() => toggleSendTicketModal(false)}>&#x2715;</div>
          </ModalHeader>
          <ModalBody>
            <>
              {floorplan && floorplan.floorPlan ? null :
                <>
                  <div className="u-mgn-btm-sm">
                    <p className="p__1">Seats: {
                      seatState.selectedSeats
                        ? Object.keys(seatState.selectedSeats).join(", ")
                        : "No seats selected"
                    }</p>
                </div>
              <div className="u-mgn-btm-sm">
                <Input className={"p__1"} inputProps={{ required: true, name: "fullName", onChange: handleSendTicket }} label="Full name" />
              </div>
              <div className="u-mgn-btm-sm">
                <Input className={"p__1"} inputProps={{ required: true, name: "email", onChange: handleSendTicket }} label="Email" />
              </div>
              {floorplan.length > 0 ?
                null :
                <div className="u-mgn-btm-sm">
                  <Input className={"p__1"} inputProps={{ required: true, name: "numTickets", onChange: handleSendTicket, type: "number", min: 0, step: 1 }} label="Number of tickets" />
                </div>
              }
              {isLoading ?
                <ClipLoader color="#0029FF" loading={true} css={""} size={50} speedMultiplier={.3} />
                :
                <Button buttonProps={{ onClick: submitGratis }}>Send tickets</Button>
              }
            </>
              }
          </>
        </ModalBody>
      </Modal>


      {/* Category management */}
      <Modal isOpen={categoryModalOpen} toggle={() => toggleCategoryModal(!categoryModalOpen)}>
        <ModalHeader>
          Edit Category
          <div className="modal__close" onClick={() => toggleCategoryModal(false)}>&#x2715;</div>
        </ModalHeader>
        <ModalBody>
          <div className="u-pad-md">
            <div className="u-mgn-btm-sm">
              <p className="p__1">Category Name</p>
              <Input className={"p__1"} inputProps={{ required: true, name: "name", onChange: handleCategoryEdit, placeholder: activeCategoryEdit.name, value: activeCategoryEdit.name }} />
            </div>
            <div className="u-mgn-btm-sm">
              <p className="p__1">Total Tickets Available</p>
              <Input className={"p__1"} inputProps={{ required: true, type: "integer", step: 1, name: "tickets_available", onChange: handleCategoryEdit, placeholder: activeCategoryEdit.tickets_available, value: activeCategoryEdit.tickets_available }} />
            </div>
            {activeCategoryEdit.subcategories && activeCategoryEdit.subcategories.length > 0 ? null :
              <div className="u-mgn-btm-sm">
                <p className="p__1">Category price</p>
                <Input price={true} className={"p__1"} inputProps={{ type: "number", required: true, name: "price", onChange: handleCategoryEdit, placeholder: activeCategoryEdit.price, value: activeCategoryEdit.price }} />
              </div>
            }
            <Button buttonProps={{ onClick: updateCategory }}>Update Ticket Category</Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={ticketCategoriesModalOpen} toggle={() => toggleTicketCategoriesModal(!ticketCategoriesModalOpen)}>
        <ModalHeader>
          Add a new ticket category
          <div className="modal__close" onClick={() => toggleTicketCategoriesModal(false)}>&#x2715;</div>
        </ModalHeader>
        <ModalBody>
          <div className="u-pad-md">
            <div className="u-mgn-btm-sm">
              <p className="p__1 u-mgn-btm-md">Set New Ticket Category</p>
              <form>
                <Input inputProps={{ type: "text", placeholder: "Name", name: "name", onChange: handleNewTicketCategoryData }} label="Category Name" />

                <Input price={true} inputProps={{ type: "float", placeholder: "0.00", name: "price", onChange: handleNewTicketCategoryData }} label="Ticket Price" />
                {floorplan.length < 1 &&
                  <Input inputProps={{ type: "integer", placeholder: "50", name: "numTix", onChange: handleNewTicketCategoryData }} label="Number of tickets" />
                }
                <Button buttonProps={{ onClick: addNewTicketCategory, type: "submit" }}>Add</Button>
              </form>
            </div>

          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={confirmCategoryDelete} toggle={() => toggleConfirmCategoryDelete(!confirmCategoryDelete)}>
        <ModalHeader>
          Confirm Category Deletion
          <div className="modal__close" onClick={() => toggleConfirmCategoryDelete(false)}>&#x2715;</div>
        </ModalHeader>
        <ModalBody>
          <h3 className="h__3">Are you sure you want to delete this category? This will not impact customers who have already used it.</h3>
          <Button buttonProps={{ onClick: () => "delete ticket categories" }}>Yes, delete</Button>
        </ModalBody>
      </Modal>

      <Modal isOpen={editTicketModalOpen} toggle={() => toggleEditTicketModal(!editTicketModalOpen)}>
        <ModalHeader>
          Edit Tickets
          <div className="modal__close" onClick={() => toggleEditTicketModal(false)}>&#x2715;</div>
        </ModalHeader>
        <ModalBody>
          <div className="ticket-manager__edit">

            {seatState.selectedSeats && Object.keys(seatState.selectedSeats).map(seat => (
              <p className="p__1">Seat: {seat}</p>

            ))}
          </div>
        </ModalBody>
      </Modal>
    </div >
    </>
  )
}