import React, { useState } from "react"
import whiteLogo from "../assets/img/SVG/slicktix_logo_white.svg"
import { ReactComponent as Logo } from '../assets/img/SVG/logo-blue.svg';
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";


export default function Footer(props) {
    const [contactModalOpen, toggleContactModal] = useState(false);

    return (
        <>
            <footer className={`footer ${props.className ? props.className : "footer__sm_flex_col"}`}>
                <div className="footer__logo-box">
                    <Link to="/workwithus">
                        {props.className === "footer__white" ?
                            <img
                                src={whiteLogo}
                                alt="footer-logo"
                                className="footer__logo" />

                            :
                            <Logo className="footer__logo" />
                        }
                    </Link>
                </div>
                <div className="footer__right">
                    <Link to="/events">
                        <p className={`${props.className === "footer__white" ? "sm_d_none" : ""}`}>Explore</p>
                    </Link>
                    {/* <p className={`${props.className === "footer__white" ? "sm_d_none" : ""}`}>Launch</p> */}
                    <Link to="/workwithus">
                        <p className={`${props.className === "footer__white" ? "sm_d_none" : ""}`}>About</p>
                    </Link>
                    <p onClick={() => toggleContactModal(true)} className={`u-hover-pointer ${props.className === "footer__white" ? "sm_d_none" : ""}`}>Contact</p>
                    <Link to="/tos">
                        <p>Terms</p>
                    </Link>
                </div>
            </footer>
            <Modal isOpen={contactModalOpen} toggle={() => toggleContactModal(!contactModalOpen)}>
                <ModalHeader>
                    Contact
                    <div className="modal__close" onClick={() => toggleContactModal(false)}>&#x2715;</div>
                </ModalHeader>
                <ModalBody>
                    <div className="u-pad-md">
                        <p className="p__1 u-mgn-btm-sm">For all inquiries, please reach out to us at: </p>
                        <p className="p__1">info@slick-tix.org</p>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

