import React, { useState } from "react";

export default function ImageUpload({ label = false, inputProps, small = false, subLabel=false }) {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    // If there's an additional onChange handler passed in inputProps, call it
    if (inputProps.onChange) {
      inputProps.onChange(event);
    }
  };

  return (
    <div className={`image-upload ${small ? "image-upload--small" : ""}`}>
      {label ? <label className="image-upload__label">{label}</label> : null}
      {subLabel ? <label className="image-upload__sublabel">{subLabel}</label> : null}
      <div className={`image-upload__container ${file ? "image-upload__container--file" : ""}`}>
        <input
          {...inputProps}
          type="file"
          id={`image-upload__input-${inputProps.name}`}
          className="image-upload__input"
          onChange={handleFileChange}
          accept="image/*"
          multiple={false}
        />
        <label htmlFor={`image-upload__input-${inputProps.name}`} className="image-upload__body">
          <div className="image-upload__placeholder">+</div>
        </label>
        {file && (
          <div className="image-upload__feedback">
            <div>{file.name}</div>
            {file.type.startsWith("image/") && (
              <img
                src={URL.createObjectURL(file)}
                alt="Uploaded"
                className="image-upload__preview"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}