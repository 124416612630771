// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import API from './API';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [showrunner, setShowrunner] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchAuth = async () => {
    try {
      const res = await API.getAuth();
      if (!res.data.anonymous) {
        setAuth(res.data.user);
        setShowrunner(res.data.showrunner);
        setIsAuthenticated(true);
        setLoading(false);
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching auth", error);
      setIsAuthenticated(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, showrunner, isAuthenticated, setAuth, loading, fetchAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
