import React, { useState, useEffect } from 'react';
import Input from '../../../components/Input';
import Button from '../../../components/buttons/Button';
import ReactQuill from 'react-quill';
import API from '../../../utils/API';
import utils from '../../../utils/utils';
import ImageUpload from '../../../components/ImageUpload';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../utils/authContext';
import { ClipLoader } from "react-spinners";

export default function CreateEvent(props) {
  const { showrunner } = useAuth();

  const [newProd, setNewProd] = useState({
    name: "",
    portrait_poster: null,
  });
  const [newProdDesc, setNewProdDesc] = useState("");
  const [newShow, setNewShow] = useState({
    date: "",
    theater: "",
    location: "",
    venue: "",
    private: false,
  });
  const [step, setStep] = useState(1);
  const [theaters, setTheaters] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    API.getTheaters().then(res => {
      console.log("Theaters", res.data)
      setTheaters(res.data);
    });
  }, []);

  function handleNewShowChange(e) {
    const { name, value, checked } = e.target;
    console.log(name, value);
    if (name === "private") {
      setNewShow(prev => ({ ...prev, [name]: checked }));
    } else {
      setNewShow(prev => ({ ...prev, [name]: value }));
    }
  }
  function handleNewProdChange(e) {
    const { name, type, value, files } = e.target;
    if (!e.target) return;
    if (type === "file") {
      setNewProd(prev => ({ ...prev, [name]: files[0] }));
    } else {
      setNewProd(prev => ({ ...prev, [name]: value }));
    }
  }

  function addEvent() {
    setLoading(true);
    const req = new FormData();
    Object.keys(newProd).forEach(key => {
      req.append(key, newProd[key]);
    });
    req.append('users', showrunner.id);
    req.append('description', newProdDesc);
    req.append("stripe_connect_id", showrunner.stripe_connect_id);

    // Add show data to FormData
    Object.keys(newShow).forEach(key => {
      let value = newShow[key];
      if (typeof value === 'boolean') {
        value = value ? "True" : "False";
      }
      req.append(key, value);
    });

    if (!newProd.poster_portrait) {
      utils.sendAlert("Please upload a poster", "error");
      return
    }
    if (!newProd.name) {
      utils.sendAlert("Please enter a title", "error");
      return
    }
    API.addEvent(req).then(res => {
      utils.sendAlert("New Event Created", "success")
      setLoading(false);
      const { theater, production, id } = res.data.show; // Assuming your response has this structure

      // Navigate to the new show management page with state
      history.push({
        pathname: '/showrunner/show',
        search: `?theater=${theater}&production=${production}&showID=${id}`
      });
    }).catch(err => {
      if (err.response.data) {
        if (err.response.data.poster_portrait) {
          utils.sendAlert(err.response.data.poster_portrait[0], "error");
        }
      }
      utils.sendAlert("Failed to create new event.", "error");
      setLoading(false);
    });
  }

  return (
    <div className='create-event'>
      <h1 className='create-event__heading'>Create Event</h1>
      {step === 1 ?
        <div className="create-event__1">
          <div className="create-event__1--left">
            <Input inputProps={{ type: "text", placeholder: "Production name", name: "name", onChange: handleNewProdChange, required: true }} label="Event Title" />
            <Input inputProps={{ type: "text", placeholder: "Venue", name: "venue", onChange: handleNewShowChange }} label="Venue" />
            <Input inputProps={{ type: "text", placeholder: "Address", name: "location", onChange: handleNewShowChange }} label="Address" />
            <Input inputProps={{ type: "datetime-local", name: "date", onChange: handleNewShowChange }} label="Date and Time" />
            <div className="input__label">Description</div>
            <ReactQuill theme="snow" value={newProdDesc} name="description" onChange={setNewProdDesc} />
            <div className="u-mgn-vert-md launch-event__private__group">
              <input
                type="checkbox"
                name="private"
                className='input__checkbox u-mgn-right-sm'
                onChange={handleNewShowChange}
              />
              <label className="input__label" htmlFor="private">Make this event private</label>
            </div>
            <div className="u-mgn-vert-md">
              <label className="input__label" htmlFor="theater">Floorplan (leave blank if none)</label>
              <div>
                <select className="input ch-p1" name="theater" onChange={handleNewShowChange}>
                  <option>None</option>
                  {theaters.map(theater => <option key={theater.id} value={theater.name}>{theater.name}</option>)}
                  {/* <option value={"Chorus Hall"}>Chorus Hall</option>
                  <option value={"palace"}>Palace of Fine Arts</option> */}
                </select>
              </div>
            </div>
          </div>
          <div className="create-event__1--right">
            <div className="create-event__1--img-upload">
              <ImageUpload label="Poster" subLabel="(Portrait orientation recommended)" inputProps={{ type: "file", name: "poster_portrait", onChange: handleNewProdChange }} />
            </div>
            {loading ?
              <ClipLoader color='#0029FF' /> :
              <Button buttonProps={{ onClick: addEvent }}>Continue</Button>
            }
          </div>
        </div>
        : null}
      {step === 2 ?
        <div className="create-event__2">
          {/* Step 2 content */}
        </div>
        : null}
      {step === 3 ?
        <div className="create-event__3">
          {/* Step 3 content */}
        </div>
        : null}
    </div>
  )
}