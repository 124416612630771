// Sector.jsx
import React from 'react';
import Section from './Section';

const Sector = ({ sector, data, seatClick, selectedSeats, onSectionClick }) => {
  return (
    <div className="floorplan__sector u-flex u-mgn-btm-md">
      {sector.sections.map((section, index) => (
        <div className='floorplan__section__wrapper' key={index} onClick={() => onSectionClick(section)}>
          <div className="floorplan__section__cover">
          </div>
          <Section
            section={section}
            data={data}
            seatClick={seatClick}
            selectedSeats={selectedSeats}
          />
        </div>
      ))}
    </div>
  );
};

export default Sector;