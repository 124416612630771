import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';  

const TermsOfService = () => {
  return (
    <>
    <Navbar />
    <div className="u-layout-page u-pad-md">
      <h1 className="heading__1">Terms of Service</h1>
      <p className="p__1">Last Updated: 06/23/2024</p>

      <section>
        <h2 className="heading__2">Welcome to Slick Tix!</h2>
        <p className="p__2">
          These Terms of Service ("ToS") govern your use of our platform, which enables the creation and purchase of event tickets. By accessing or using our services, you agree to comply with and be bound by these terms.
        </p>
      </section>

      <section>
        <h2 className="heading__2">1. Definitions</h2>
        <p className="p__2">
          <strong>Slick Tix:</strong> Refers to the Slick Tix LLC platform.
        </p>
        <p className="p__2">
          <strong>Client:</strong> Event producers who create and monetize events using Slick Tix.
        </p>
        <p className="p__2">
          <strong>Customer:</strong> Individuals who purchase tickets for events through Slick Tix.
        </p>
        <p className="p__2">
          <strong>Services:</strong> All functionalities provided by Slick Tix, including but not limited to event creation, ticket sales, and payment processing.
        </p>
      </section>

      <section>
        <h2 className="heading__2">2. Acceptance of Terms</h2>
        <p className="p__2">
          By using our services, Clients and Customers agree to these ToS. If you do not agree, please refrain from using our services.
        </p>
      </section>

      <section>
        <h2 className="heading__2">3. Changes to Terms</h2>
        <p className="p__2">
          Slick Tix reserves the right to modify these ToS at any time. Changes will be communicated via email or through our platform. Continued use of the services constitutes acceptance of the updated terms.
        </p>
      </section>

      <section>
        <h2 className="heading__2">4. Use of Services</h2>
        <h3 className="heading__3">4.1 For Clients</h3>
        <ul>
          <li className="p__2">Account Creation: Clients must create an account and sign up with Stripe via our dashboard to start creating and monetizing events.</li>
          <li className="p__2">Event Creation: Clients can create events, set ticket prices, and publish events on the Slick Tix platform.</li>
          <li className="p__2">Monetization: Clients will receive payments through Stripe Connect. All fees and charges will be detailed during the account setup.</li>
          <li className="p__2">Data Access: Clients can access the email addresses of Customers who purchased tickets for their events. This data is for the sole purpose of event management and marketing.</li>
          <li className="p__2">Analytics: Clients can view analytics related to their events, including sales data and attendee information.</li>
          <li className="p__2">Refunds: Clients are responsible for managing and processing refunds for their events.</li>
        </ul>
        <h3 className="heading__3">4.2 For Customers</h3>
        <ul>
          <li className="p__2">Ticket Purchase: Customers can purchase tickets without creating an account. They must provide a valid email address and name to receive their tickets.</li>
          <li className="p__2">Payment: All payments are processed through Stripe. Customers must provide valid payment information to complete the transaction.</li>
          <li className="p__2">Ticket Delivery: Tickets will be sent to the email address provided by the Customer upon successful payment.</li>
          <li className="p__2">Refunds: Requests for refunds must be directed to the Client organizing the event. Slick Tix is not responsible for processing refunds.</li>
        </ul>
      </section>

      <section>
        <h2 className="heading__2">5. Fees</h2>
        <p className="p__2">
          Clients: A box office fee is charged for each ticket sold. This fee is detailed in the Client's account settings.
        </p>
        <p className="p__2">
          Customers: Ticket prices and any applicable fees are displayed at the point of purchase. The displayed fee includes Stripe's credit card processing charge and the box office fee. This consolidated fee ensures transparency and ease of understanding for the Customer.
        </p>
      </section>

      <section>
        <h2 className="heading__2">6. Privacy</h2>
        <p className="p__2">
          Slick Tix values your privacy. Our Privacy Policy explains how we collect, use, and protect your information. By using our services, you consent to our data practices.
        </p>
      </section>

      <section>
        <h2 className="heading__2">7. Security</h2>
        <p className="p__2">
          We take security seriously and implement measures to protect your data. However, we cannot guarantee absolute security and encourage you to take precautions when sharing sensitive information.
        </p>
      </section>

      <section>
        <h2 className="heading__2">8. Limitation of Liability</h2>
        <p className="p__2">
          Slick Tix is not liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
        </p>
        <ul>
          <li className="p__2">Your use of the services</li>
          <li className="p__2">Any unauthorized access to or use of our servers</li>
          <li className="p__2">Any interruption or cessation of transmission to or from our services</li>
          <li className="p__2">Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party</li>
        </ul>
      </section>

      <section>
        <h2 className="heading__2">9. Governing Law</h2>
        <p className="p__2">
          These ToS are governed by and construed in accordance with the laws of the jurisdiction in which Slick Tix LLC is established, without regard to its conflict of law principles.
        </p>
      </section>

      <section>
        <h2 className="heading__2">10. Contact Us</h2>
        <p className="p__2">
          If you have any questions about these ToS, please contact us at info@slick-tix.org.
        </p>
      </section>
    </div>
    <Footer />
    </>
  );
};

export default TermsOfService;