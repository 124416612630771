import React from 'react';
import Seat from '../../components/Seat';

const Rows = ({ rows, data, seatClick, selectedSeats, isEventManager }) => {
  const renderSeats = (row, data, seatClick, selectedSeats) => {
    const seats = [];
    let counter = 0;
    let nameNum = row.startFrom;

    const radius = 500; // Radius for the curvature

    for (let j = 0; j < row.numSeats; j++) {
      let seatName;
      let seatNum;

      if (row.countingScheme === 'odd') {
        seatNum = nameNum + j * 2;
        seatName = row.name + seatNum;
      } else if (row.countingScheme === 'even') {
        seatNum = row.direction === 'ltr'
          ? nameNum + j * 2
          : nameNum + (row.numSeats - 1 - j) * 2;
        seatName = row.name + seatNum;
      } else {
        seatNum = row.direction === 'ltr'
          ? nameNum + j
          : nameNum + (row.numSeats - 1 - j);
        seatName = row.name + seatNum;
      }

      const seatData = data[seatName];
      const selected = selectedSeats ? !!selectedSeats[seatName] : false;
      const blank = row.emptys && row.emptys.includes(seatNum);
      const ada = row.ada && row.ada.includes(seatNum);
      const taken = seatData ? seatData.is_taken : false;
      const color = seatData ? seatData.color : '';

      const angle = (j - row.numSeats / 2) * (Math.PI / row.numSeats); // Calculate angle for curvature
      const verticalDisplacement = Math.sin(angle) * radius;

      const props = {
        key: `seatKey${counter++}`,
        name: seatName,
        selected,
        blank,
        onClick: seatClick,
        color,
        taken,
        ada,
        data: seatData,
        isEventManager,
        style: {
          transform: `translateY(${verticalDisplacement}px)`
        }
      };

      seats.push(<Seat {...props} />);
    }
    return seats;
  };

  return (
    <div className="rows">
      {rows.map((row, index) => (
        <div key={index} className={`row u-flex ${row.direction === 'ltr' ? "u-flex-justify-end" : "u-flex-justify-start"}`}>
          {row.direction === 'ltr' && <span className="row-name u-mgn-right-md">{row.name}</span>}
          {renderSeats(row, data, seatClick, selectedSeats)}
          {row.direction === 'rtl' && <span className="row-name u-mgn-right-md">{row.name}</span>}
        </div>
      ))}
    </div>
  );
};

export default Rows;