import React, { useEffect, useState } from "react";
import API from "../../../utils/API";
import Input from "../../../components/Input";
import Button from "../../../components/buttons/Button";
import { Table, TableBody, TableHead, TableRow, TH, TD } from "../../../components/Table";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import utils from "../../../utils/utils";
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from "react-router-dom";

export default function Discounts(props) {
  const location = useLocation();
  const initDiscount = {
    discount_type: "cash_amount",
    amount: 0,
    name: "",
    discount_code: "",
    id: 0
  }

  const [discountCodeData, setDiscountCodeData] = useState({ discount_type: "cash_amount" })
  const [discounts, setDiscounts] = useState([])
  const [activeDiscount, setActiveDiscount] = useState(initDiscount)
  const [confirmDiscountDelete, toggleConfirmDiscountDelete] = useState(false)

  useEffect(() => {
    if (props.showID) {
      reset()
    }
  }, [props.showID])

  function reset() {
    const params = new URLSearchParams(location.search);
		const showID = params.get("showID");
    API.getDiscounts(showID)
      .then(res => {
        setDiscounts(res.data)
      })
  }

  function handleDiscountCodeChange(e) {
    setActiveDiscount({ ...activeDiscount, [e.target.name]: e.target.value })
  }

  function createDiscountCode(e) {
    if (activeDiscount.id === 0) {
      const req = activeDiscount
      req["show"] = props.showID
      API.createDiscountCode(req)
        .then(res => {
          utils.sendAlert("Discount Code Created", "success");
          reset();
        })
    } else {
      API.editDiscount(activeDiscount)
        .then(res => {
          utils.sendAlert("Discount Code Updated", "success");
          reset();
        })
    }
  }

  function deleteDiscount() {
    API.deleteDiscount(activeDiscount.id)
      .then(res => {
        utils.sendAlert("Discount Deleted", "success")
        toggleConfirmDiscountDelete(false)
        reset()
      })
  }

  return (
    <>
      <div className="ticket-manager__discounts">
        <h4 className="heading__4">Discount Codes 
        </h4>
        <Table>
          <TableHead>
            <TableRow>
              <TH>Discount Name</TH>
              <TH>Discount Code</TH>
              <TH>Discount Amount</TH>
              <TH>Uses</TH>
              <TH></TH>
            </TableRow>
          </TableHead>
          <TableBody>
            {discounts.map(discount => (
              <TableRow key={discount.id}>
                <TD>{discount.name}</TD>
                <TD>{discount.discount_code}</TD>
                <TD>{discount.discount_type === "cash_amount" ? `$${discount.amount}` : `${discount.amount}%`}</TD>
                <TD>{discount.uses}</TD>
                <TD>
                  <div className="ticket-manager__icons">
                    <div className="ticket-manager__icon" onClick={() => { setActiveDiscount(discount); }}>
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <div className="ticket-manager__icon" onClick={() => { setActiveDiscount(discount); toggleConfirmDiscountDelete(true); }}>
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                </TD>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="">
        <h4 className="heading__4 u-mgn-vert-md">{activeDiscount.id != 0 ? `Edit ${activeDiscount.name}` : "Create New Discount Code"}</h4>
        <div className="u-mgn-btm-sm">
          <p className="p__1">Discount Name</p>
          <Input className={"p__1"} inputProps={{ required: true, name: "name", onChange: handleDiscountCodeChange, value: activeDiscount.name }} />
        </div>
        <div className="u-mgn-btm-sm">
          <p className="p__1">Discount Code</p>
          <Input className={"p__1"} inputProps={{ required: true, name: "discount_code", onChange: handleDiscountCodeChange, value: activeDiscount.discount_code }} />
        </div>
        <div className="u-mgn-btm-sm">
          <p className="p__1">Discount Type</p>
          <select className="input ch-p1" required={true} name="discount_type" onChange={handleDiscountCodeChange}>
            <option selected={activeDiscount.discount_type === "cash_amount"} value="cash_amount">
              $ Amount
            </option>
            <option value="percentage" selected={activeDiscount.discount_type === "percentage"}>
              % Amount
            </option>
          </select>
        </div>
        <div className="u-mgn-btm-sm">
          <p className="p__1">Discount Amount</p>
          <Input className={"p__1"} inputProps={{ type: "integer", required: true, name: "amount", onChange: handleDiscountCodeChange, value: activeDiscount.amount }} />
        </div>
        <Button buttonProps={{ onClick: createDiscountCode }}>{activeDiscount.id === 0 ? "Create Discount Code" : "Edit Disount Code"}</Button>
      </div>
      {/* MODALS */}
      <Modal isOpen={confirmDiscountDelete} toggle={() => toggleConfirmDiscountDelete(!confirmDiscountDelete)}>
        <ModalHeader>
          Confirm Discount Deletion
          <div className="modal__close" onClick={() => toggleConfirmDiscountDelete(false)}>&#x2715;</div>
        </ModalHeader>
        <ModalBody>
          <h3 className="h__3">Are you sure you want to delete this discount? This will not impact customers who have already used it.</h3>
          <Button buttonProps={{ onClick: deleteDiscount }}>Yes, delete</Button>
        </ModalBody>
      </Modal>
    </>
  )
}