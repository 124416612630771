import React, { useEffect, useState } from "react";
import { useAuth } from '../../utils/authContext';
import { Link } from "react-router-dom";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryBar } from 'victory';
import { Table, TableBody, TableHead, TableRow, TH, TD } from "../../components/Table";
import API from "../../utils/API";
import utils from "../../utils/utils";
import StripeSignUp from "./StripeSignUp";

const flashyTheme = {
	axis: {
		style: {
			axis: {
				stroke: "white", // Color of the axis line
				strokeWidth: 2,
			},
			axisLabel: {
				fontSize: 20,
				padding: 30,
				fill: "#0029FF", // Color of the axis label
			},
			grid: {
				stroke: "none", // Remove grid lines
			},
			ticks: {
				stroke: "transparent",
				size: 5,
				strokeWidth: 2,
			},
			tickLabels: {
				fontSize: 15,
				padding: 5,
				fill: "#A2A2A2", // Color of the tick labels
			},
		},
	},
	line: {
		style: {
			data: {
				strokeWidth: 3,
				strokeLinecap: "round",
			},
			labels: {
				fontSize: 15,
				fill: "#0029FF",
			},
		},
	},

};

export default function Home(props) {
  const { auth, showrunner, loading } = useAuth();

  const [stripeDone, setStripeDone] = useState(false);
  const [events, setEvents] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [ticketsData, setTicketsData] = useState([]);
  const [salesTotal, setSalesTotal] = useState(0);
  const [ticketsTotal, setTicketsTotal] = useState(0);
  const [recentOrders, setRecentOrders] = useState([]);
  const [stripeAcctLink, setStripeAcctLink] = useState(false);
  // const [onboardingComplete, setOnboardingComplete] = useState(false);

  useEffect(() => {
    if (!loading && showrunner.id) {
      // showrunner.stripe_connect_id ? setStripeDone(true) : setStripeDone(false);
      if (showrunner.stripe_connect_id) {
        API.getStripeConnectDash(showrunner.stripe_connect_id)
        .then(res => {
          const { link, error, onboarding } = res.data
          console.log("Stripe connect dash res", res)
          if (link) {
            setStripeAcctLink(link)
            onboarding.status === "incomplete" ? setStripeDone(false) : setStripeDone(true);
            // display link
          } else if (error) {
            // display error
            setStripeDone(false);
            console.log("Stripe connect error", error)
          }
        })
        .catch(e => {
          console.error("Error getting stripe connect dash", e)
        })
      }
      // setStripeDone(false);
      setEvents(showrunner.productions);
      // Sales query
      API.getOrdersChart(showrunner.id, "revenue", "week")
        .then(res => {
          console.log("revenue res", res.data)
          const transformedData = res.data.chart.map(item => ({
            x: new Date(item.date_created__date),
            y: item.total_revenue
          }));
          setSalesTotal(res.data.total);

          setSalesData(transformedData);
        })
    }

    // Tickets query
    if (!showrunner) return;
    API.getOrdersChart(showrunner.id, "tickets", "week")
      .then(res => {
        console.log("Tickets res", res.data)
        const transformedData = res.data.chart.map(item => ({
          x: new Date(item.order__date_created__date),
          y: item.tickets_sold
        }));
        console.log("Tickets data", transformedData);
        setTicketsTotal(res.data.total);
        setTicketsData(transformedData);
      });

    if (!showrunner) return;
    API.getRecentOrders(showrunner.id, 10, 7)
      .then(res => {
        console.log("Recent orders", res.data)
        setRecentOrders(res.data);
      })
  }, [showrunner]);


  return (
    <div>
      <h3 className="dash-home__heading">Dashboard</h3>
      <p className="p__2">Welcome, {showrunner && showrunner.first_name}</p>

      {stripeDone && (
        <div className="dash-home__charts u-mgn-top-md">
          <Link to="/showrunner/financial">


            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Sales volume this week:</p>
              <p className="dash-home__chart__total">Total: <span>${salesTotal.toFixed(2)}</span></p>
              {salesData.length > 0 &&
                <VictoryChart theme={flashyTheme}>
                  <VictoryAxis
                    tickFormat={(x) => {
                      const date = new Date(x);
                      const options = { weekday: 'short' };
                      return date.toLocaleDateString('en-US', options);
                    }}
                    tickCount={7} // Adjust the number of ticks to match the data range
                  />
                  <VictoryAxis
                    dependentAxis
                    tickFormat={(y) => `$${y.toFixed(2)}`}
                    style={{
                      tickLabels: {
                        fontSize: 12,
                      },
                      grid: {
                        stroke: "rgba(162, 162, 162, 0.2)", // Show horizontal grid lines
                        strokeDasharray: "0",
                    },
    
                    }}
                  />
                  <VictoryLine
                    data={salesData}
                    x="x"
                    y="y"
                    style={{ data: { stroke: "#B4BCFF" } }}
                    labels={({ datum }) => `$${datum.y.toFixed(2)}`}
                    labelComponent={<VictoryTooltip />}
                  />
                </VictoryChart>
              }
            </div>
          </Link>

          <Link to="/showrunner/financial">
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Tickets sold this week:</p>
              <p className="dash-home__chart__total">Total: <span>{ticketsTotal}</span></p>
              {ticketsData.length > 0 && (
                <VictoryChart
                  theme={flashyTheme}
                  scale={{ x: "time" }}
                  domainPadding={{ x: 20 }}
                >
                  <VictoryAxis
                    tickFormat={(x) => {
                      const date = new Date(x);
                      const options = { weekday: 'short' };
                      return date.toLocaleDateString('en-US', options);
                    }}
                    tickCount={7} // Adjust the number of ticks to match the data range
                  />
                  <VictoryAxis
                    dependentAxis
                    tickFormat={(y) => `${y}`}
                    style={{
                      tickLabels: {
                        fontSize: 12,
                      },
                      grid: {
                        stroke: "rgba(162, 162, 162, 0.2)", // Show horizontal grid lines
                        strokeDasharray: "0",
                    },
                    }}
                  />
                  <VictoryBar
                    data={ticketsData}
                    x="x"
                    y="y"
                    style={{ data: { fill: "#FDB9EE", borderRadius: "10px" } }}
                    labels={({ datum }) => `${datum.y}`}
                    labelComponent={<VictoryTooltip />}
                  />
                </VictoryChart>
              )}
            </div>
          </Link>

        </div>
      )}

      {
        !stripeDone &&
        <>
          <StripeSignUp showrunner={showrunner} userID={auth ? auth.id : null} incomplete={(showrunner && showrunner.stripe_connect_id) && !stripeDone}/>
        </>
      }
      {stripeDone && (

        <div className="dash-home__orders">
          <h5 className="heading__5 u-mgn-btm-sm">Recent Orders</h5>
          <Table>
            <TableHead>
              <TableRow>
                <TH>Date</TH>
                <TH>Event</TH>
                <TH>Customer</TH>
                <TH>Email</TH>
                <TH>Order Value</TH>
                <TH>Tickets Sold</TH>
              </TableRow>
            </TableHead>
            <TableBody>
              {recentOrders.map(order => (
                <TableRow key={order.date_created__date}>
                  <TD>{utils.formatDateTime(order.date_created)[0]}</TD>
                  <TD>{order.show}</TD>
                  <TD>{order.full_name}</TD>
                  <TD>{order.email}</TD>
                  <TD>{`$${order.total.toFixed(2)}`}</TD>
                  <TD>{order.tickets && order.gratis ? order.tickets.split(",").length : order.tickets_sold}</TD>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div >
  )
}

{/* 

*Link to sign up with Stripe if not done already

*Ongoing Events. If none, link to create one

*Revenue in the last [selectable amount of time]. Link to sign up with Stripe if not done. Otherwise, link to finance tab

*Tickets sold in [selectable amount of time]. Link to events tab. Link to create show if none are currently happening

*Recent orders. Link to orders tab. 

*/}