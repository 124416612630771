import React, { useState } from "react";
import Input from "./Input";
import Button from "./buttons/Button";
import { ClipLoader } from "react-spinners";
import API from "../utils/API";
import utils from "../utils/utils";
import sha256 from 'crypto-js/sha256';

export default function SignUp({ toggleModal, eventData }) {
  const [signUpData, setSignUpData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    referrer: ""
  });
  const [howDidYouHear, setHowDidYouHear] = useState("");
  const [signupPending, setSignupPending] = useState(false);

  function handleSignUpChange(e) {
    setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  }

  function handleHDYH(e) {
    setHowDidYouHear(e.target.value);
  }

  function signup(e) {
    e.preventDefault();
    if (!signUpData.email || !signUpData.first_name || !signUpData.last_name || !signUpData.password || !howDidYouHear) {
      utils.sendAlert("Please fill out all fields", "error");
      return;
    }

    // Validate event data if creating an event
    if (eventData) {
      const { name, date, venue, location } = eventData;
      if (!name || !date || !venue || !location) {
        utils.sendAlert("To start an event draft, you must at least provide: an event name, a date, a venue and location.", "error");
        toggleModal && toggleModal(false);
        return;
      }
      // Convert boolean values to Django-friendly strings
      if (typeof eventData.private === "boolean") {
        eventData.private = eventData.private ? "True" : "False";
      }

    }
    const req = {
      user: signUpData,
      hdyh: howDidYouHear,
      create_event: !!eventData, // Set this flag to true if eventData is provided
      event_data: eventData || {} // Include event data if available
    };
    setSignupPending(true);
    console.log("eventData", eventData);
    API.signUp(req)
      .then((res) => {
        console.log(res);
        const { show_id } = res.data; // Extract the show_id from the response if event was created

        // If event was created and there is a poster to upload
        if (show_id && eventData.portrait_poster) {
          const formData = new FormData();
          // Add production data
          formData.append('production.poster_portrait', eventData.portrait_poster);

          // Add showID
          formData.append('showID', show_id);
          utils.sendAlert("Event and account created! Please verify your email address to view your new event in your dashboard.", "success");

          return API.updateEvent(formData)
            .then((res) => {
              console.log("Update show res", res);
              // utils.sendAlert("Event created and poster uploaded successfully!", "success");
            })
            .catch((err) => {
              console.error("Failed to upload poster:", err);
              utils.sendAlert("Event created, but failed to upload poster. Please upload the poster later.", "warning");
            });
        } else {
          utils.sendAlert("Please verify your email address to complete the sign-up process. You should receive an email shortly.", "success");
        }
        if (howDidYouHear === "Reddit") {
          try {
            window.rdt('track', 'SignUp');
            console.log("Reddit signup event sent");
          } catch (err) {
            console.error("Failed to send Reddit signup event", err);
          }
        } else if (howDidYouHear === "Google") {
          try {
            const hashedEmail = sha256(signUpData.email.trim().toLowerCase()).toString();
            window.gtag('event', 'conversion_event_signup_3', {
              'send_to': 'AW-CONVERSION_ID/CONVERSION_LABEL',
              'value': 150.00,
              'currency': 'EUR',
              'user_data': {
                'email': hashedEmail
              }
            });
          } catch (err) {
            console.error("Failed to send Google signup event", err);
            API.notifyAdmin({subject: "Error sending Google sign up event", message: err.message});
          }
        }
      })
      .then(() => {
        setSignupPending(false);
        toggleModal && toggleModal(false); // Close the modal after success if toggleModal is passed
      })
      .catch(signUpErr => {
        console.log(signUpErr.response);
        if (signUpErr.response?.data?.username) {
          utils.sendAlert(signUpErr.response.data.username[0], "error");
        } else if (signUpErr.response?.data?.email) {
          utils.sendAlert("Email already taken. Please choose another.", "error");
        } else {
          const errorMessage = signUpErr.response?.data?.error || "Unexpected sign up error. Please contact info@slick-tix.org.";
          utils.sendAlert(errorMessage, "error");
          API.notifyAdmin({subject: "Sign up error", message: `Error for ${signUpData.email}: ${errorMessage}`});
        }
        setSignupPending(false);
      });
  }

  return (
    <div className="u-pad-md">
      <form onSubmit={signup}>
        <div className="u-mgn-btm-sm">
          <Input className="p__1" inputProps={{ type: "email", name: "email", placeholder: "Email", onChange: handleSignUpChange }} label="Email" />
        </div>
        <div className="u-mgn-btm-sm">
          <Input className="p__1" inputProps={{ type: "text", name: "first_name", placeholder: "First Name", onChange: handleSignUpChange }} label="First Name" />
        </div>
        <div className="u-mgn-btm-sm">
          <Input className="p__1" inputProps={{ type: "text", name: "last_name", placeholder: "Last Name", onChange: handleSignUpChange }} label="Last Name" />
        </div>
        <div className="u-mgn-btm-sm">
          <Input className="p__1" inputProps={{ type: "password", name: "password", placeholder: "Password", onChange: handleSignUpChange }} label="Password" />
        </div>
        <div className="u-mgn-btm-sm">
          <p className="p__2 u-mgn-btm-sm">How did you hear about us?</p>
          <select onChange={handleHDYH} className="input__select" name="howDidYouHear" id="howDidYouHear" required defaultValue="">
            <option value="" disabled>Select an option</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Google">Google</option>
            <option value="Facebook">Facebook</option>
            <option value="Reddit">Reddit</option>
            <option value="TikTok">TikTok</option>
            <option value="Instagram">Instagram</option>
            <option value="Referral">Referral</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="u-mgn-btm-sm">
          <Input className="p__1" inputProps={{ type: "text", name: "referrer", placeholder: "Referrer username", onChange: handleSignUpChange }} label="Referral username (optional)" />
        </div>
        {signupPending ? (
          <ClipLoader color="#36d7b7" />
        ) : (
          <Button buttonProps={{ type: "submit" }}>Sign Up</Button>
        )}
      </form>
    </div>
  );
}