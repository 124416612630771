import React, { useState, useEffect } from "react";
import utils from "../../utils/utils";
import Button from "../buttons/Button";
import { Link } from "react-router-dom";

export default function EventCard(props) {

  return (
    <Link to={`/event/${props.show.id}`}>
      <div className="event-card">
        <img className="event-card__img" src={props.show.production.poster_portrait} alt="" />
        <div className="event-card__info">
          <h3>{props.show.production.name}</h3>
          <p>{utils.formatDateTime(props.show.date)[0]}, at {utils.formatDateTime(props.show.date)[1]}</p>
        </div>
      </div>
    </Link>
  )
}