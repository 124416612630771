import React, { useLayoutEffect, useRef } from 'react'

import { gsap } from 'gsap'

const WWUS5 = () => {
  const shapeStyles = {
    shape1: {
      background: 'rgb(214,47,26)',
      width: '650px',
      height: '650px',
      margin: '-325px 0 0 -325px',
      borderRadius: '50%',
      position: 'absolute'
    },
    shape2: {
      background: '#C4DFFF',
      width: '440px',
      height: '440px',
      margin: '-220px 0 0 -220px',
      borderRadius: '50%',
      position: 'absolute'
    },
    shape3: {
      background: '#FF7FE3',
      width: '270px',
      height: '270px',
      margin: '-135px 0 0 -135px',
      borderRadius: '50%',
      position: 'absolute'
    }
  }

  const hoverRef = useRef(null)
  const contentRef = useRef(null)

  useLayoutEffect(() => {
    const shapes = document.querySelectorAll('.hover__shape1')

    if (!shapes.length) {
      console.error('Cursor or shapes not found')
      return
    }
    const contentRect = contentRef.current.getBoundingClientRect()
    const offsetX = -contentRect.x
    const offsetY = -contentRect.y
    console.log("contentRect",contentRect)
    const handleMouseMove = event => {
      const mouseX = event.clientX + window.scrollX + offsetX
      const mouseY = event.clientY + window.scrollY + offsetY

      gsap.to(shapes, {
        x: mouseX,
        y: mouseY,
        stagger: -0.1,
        duration: 0.2
      })
    }

    const handleMouseEnter = () => {
      gsap.to(shapes, {
        autoAlpha: 1,
        duration: 0.5
      })
    }

    const handleMouseLeave = () => {
      gsap.to(shapes, {
        autoAlpha: 0,
        duration: 0.5
      })
    }

    if (hoverRef.current) {
      document.body.addEventListener('mousemove', handleMouseMove)
      hoverRef.current.addEventListener('mouseenter', handleMouseEnter)
      hoverRef.current.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (hoverRef.current) {
        document.body.removeEventListener('mousemove', handleMouseMove)
        hoverRef.current.removeEventListener('mouseenter', handleMouseEnter)
        hoverRef.current.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [hoverRef])

  const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  return (
    <>
      <div className='wwu__section1--content hover' ref={hoverRef}>
        {/* <Hover effectType="circles"> */}
        <div className='hover__shapes'>
          <div
            className='hover__shape1 shape-1'
            style={shapeStyles.shape1}
          ></div>
          <div
            className='hover__shape1 shape-2'
            style={shapeStyles.shape2}
          ></div>
          <div
            className='hover__shape1 shape-3'
            style={shapeStyles.shape3}
          ></div>
        </div>

        <div className={isTouchDevice() ? "hover__content--mobile" : "hover__content"}  ref={contentRef}>
          <h1 className='wwu__h1 wwu__h1--hover'>Transparency</h1>
        </div>
      </div>
      {/* </Hover> */}

      <div className='wwu__section5--footer'>
        <div className='wwu__section5--info'>
          <span className='wwu__h2'>Lowest Fees. &nbsp;</span>
          <span className='wwu__p'>Lowest service fees for your customers.</span>
        </div>
        <div className='wwu__section5--info'>
          <span className='wwu__h2'>No Hidden Fees. &nbsp;</span>
          <span className='wwu__p'>
            We only charge enough to accomplish the transaction.
          </span>
        </div>
      </div>
    </>
  )
}

export default WWUS5