import React, { useEffect, useState, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import { ReactComponent as CheckMark } from "../assets/img/SVG/check-mark.svg";
import { SeatIcon, WCIcon } from "../assets/img/event/svg";

export default function Seat({
    color = "#750D37",
    taken = false,
    name = false,
    selected = false,
    blank,
    onClick,
    sm,
    data,
    ada = false,
    wc = false,
    client,
    unclickable = false,
    isEventManager = false
}) {
    const [seatColor, setSeatColor] = useState(color);
    const [isTaken, setIsTaken] = useState(taken);
    const [isSelected, setIsSelected] = useState(selected);

    useEffect(() => {
        setSeatColor(color);
        setIsTaken(taken);
    }, [color, taken, data]);

    useEffect(() => {
        setIsSelected(selected);
        setSeatColor(color);
    }, [selected, color]);

    const toggleSeat = useCallback(() => {
        if ((isTaken && !isEventManager) || blank || unclickable) {
            return;
        }

        const newSeatColor = isSelected ? color : "#310014";
        const newSelectedState = !isSelected;

        setSeatColor(newSeatColor);
        setIsSelected(newSelectedState);

        function cb() {
            setSeatColor(color);
            setIsSelected(selected);
        }
        onClick(data, cb);
    }, [isTaken, blank, unclickable, isSelected, color, onClick, data, selected, isEventManager]);

    return (
        <>
            <div
                className={`${blank ? "seat--blank" : ""} ${sm ? "seat--sm" : "seat"} ${isTaken ? "seat--taken" : ""}`}
                style={{ color: `${isTaken ? "#707070" : "#0029FF"}` }}
                onClick={toggleSeat}
            >
                {!isSelected ?
                    <>
                        {ada ? <WCIcon className="seat--wc" /> : <SeatIcon style={{ color: `${isTaken ? "#707070" : "#0029FF"}`, fill: `${isTaken ? "#707070" : "#0029FF"}` }} />}
                    </> :
                    <>
                        <CheckMark className="seat--selected" />
                    </>}
            </div>
            {blank ? null : <ReactTooltip id={`seat${name}`}>{name}</ReactTooltip>}
        </>
    );
}