import React, { useEffect, useState } from "react";
import API from "../utils/API";
import Input from "./Input";
import Button from "./buttons/Button";
import utils from "../utils/utils";

export default function Discount(props) {

  const [code, setCode] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!props.applied) {
      setIsValid(false);
    }
  }, [props.applied]);

  function handleChange(e) {
    setCode(e.target.value)
  }

  function checkCode() {
    if (props.total === 0) {
			utils.sendAlert("Select tickets before applying a discount.", "error")
			return 
		}

    if (isValid) return;
    API.checkDiscount(props.showID, code)
      .then(res => {
        const { valid, discount } = res.data;
        props.cb(valid, discount);
        if (!valid) {
          setCode("");
        } else {
          setIsValid(true);
          utils.sendAlert("Discount applied!", "success")
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className="discount">
    
      <Input inputProps={{type: "text", onChange: handleChange, placeholder: "Discount Code (Optional)"}} containerClass="discount__input"/>
      {isValid && props.applied ?
      <Button sm={true} ko={true} buttonProps={{onClick:()=>{}}} className={"discount__btn"} disabled={true}> Apply </Button>
      :
      <Button sm={true} ko={true} buttonProps={{onClick:checkCode}} className={"discount__btn"}> Apply </Button>
      }
    </div>
  )
}

