// Section.jsx
import React, { useEffect, useState } from 'react';
import Rows from './Rows';

const Section = ({ section, data, seatClick, selectedSeats, isEventManager }) => {
  const [sectionStyle, setSectionStyle] = useState({});

  return (
    <div className="floorplan__section" style={sectionStyle}>
      <div className="floorplan__rows__container">
        <Rows
          rows={section.rows}
          data={data}
          seatClick={seatClick}
          selectedSeats={selectedSeats}
          isEventManager={isEventManager}
        />
      </div>
    </div>
  );
};

export default Section;