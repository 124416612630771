import React, { useEffect, useState } from "react";
import utils from "../utils/utils";

export default function TicketTypeSelector(props) {

    // PROPS
    // ticketType: string
    // totalTix: number
    // currentTotalTix: number
    // tixAddable: number // total isn't really useful because you can only add remaining selectable tickets. In other words, when there are multiple ticket types, you can allocate amongst them, reducing the available total for other types. 

    // STATE
    let [canAdd, setCanAdd] = useState(props.tixAddable === 0 ? false : true)
    let [canRemove, setCanRemove] = useState(props.numSelected > 0 ? true : false)
    let [numSelected, setNumSelected] = useState(props.numSelected)
    useEffect(() => {
        if (props.tixAddable === 0) {
            setCanAdd(false);
        }
        if (numSelected === 1) {
            setCanRemove(true)
        }

        
        if (numSelected === 0) {
            setCanRemove(false)
        }
        if (props.tixAddable > 0) {
            setCanAdd(true)
        }
        if (props.numSelected === 0) {
            setNumSelected(0)
        }
        if (props.numSelected === 9) { 
            utils.sendAlert("You can only select up to 9 tickets at a time.", "error")
            setCanAdd(false);
        }
        if (props.freeSeating && props.numSelected >= props.tixAddable) {
            setCanAdd(false);
        }
    }, [props.tixAddable, props.numSelected])

    function handleClick(arg) {
			// Available tickets need to be affected by selections of other subcategories
        if (arg === "add") {
            if (canAdd) {
                let newNumSelected = numSelected + 1
                props.setTixAddable(props.tixAddable - 1, props.name, newNumSelected, props.obj)
                setNumSelected(newNumSelected)
                if (newNumSelected === props.tixAddable) {
                    setCanAdd(false)
                }
                if (newNumSelected === 1) {
                    setCanRemove(true)
                }
            } else {
                return
            }
        } else {
            if (canRemove) {
                let newNumSelected = numSelected - 1
                props.setTixAddable(props.tixAddable + 1, props.name, newNumSelected, props.obj)
                setNumSelected(newNumSelected)
                if (newNumSelected === 0) {
                    setCanRemove(false)
                }
                if (newNumSelected < props.tixAddable) {
                    setCanAdd(true)
                }
            } else {
                return
            }
        }
    }

    return (
        <div className="tts">
            <div className="tts__desc">
                <div className="tts__type">
                    {props.name} {props.freeSeating && !props.subCategory ? `(${props.obj.tickets_available} tickets available)` : ""}
                </div>
                <div className="tts__price">
                    ${props.price}
                </div>
            </div>
            <div className="tts__btns">
                <button className={`tts__btn ${canRemove ? "" : "tts__btn--inactive"}`} onClick={() => handleClick("remove")}>-</button>
                <div className="tts__num-selected">{numSelected}</div>
                <button className={`tts__btn ${canAdd ? "" : "tts__btn--inactive"}`} onClick={() => handleClick("add")}>+</button>
            </div>
        </div>
    )
}