import React, { useRef, useState, useEffect } from "react";
import { motion, useTransform, useScroll } from "framer-motion";

export default function HorizontalScroll(props) {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const [xTransform, setXTransform] = useState(["1%", "-60%"]); // Default transform values


  // const x = useTransform(scrollYProgress, [0, 1], ["-100%", "0%"]);
  const x = useTransform(scrollYProgress, [0, 1], xTransform);

  useEffect(() => {
    // Function to update the transform values based on screen width
    const updateTransformValues = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 900) {
        setXTransform(["1%", "-85%"]); // Smaller screens
      } else if (screenWidth <= 1315) {
        setXTransform(["1%", "-70%"]); // Medium screens
      } else {
        setXTransform(["1%", "-60%"]); // Larger screens
      }
    };

    // Initial update
    updateTransformValues();

    // Add event listener for screen resize
    window.addEventListener("resize", updateTransformValues);

    return () => {
      // Clean up event listener
      window.removeEventListener("resize", updateTransformValues);
    };
  }, []);

  return (
    <section ref={targetRef} className="hz-scroll" style={{
      height: `200vh`
    }}>
      <div className="hz-scroll__container">

        <motion.div style={{ x }} className="hz-scroll__motion">
          {props.children}
        </motion.div>
      </div>
    </section>
  );
};