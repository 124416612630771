import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import API from "../utils/API";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Button from "../components/buttons/Button";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Input from "../components/Input";
import { phoneImage, phoneShadowImage, StripeImage, MobileImage } from "../assets/img/workwithus";
import { TicketIcon, StripePhoneIcon, SalesIcon, BarsIcon, ScanIcon, SeatsIcon, Particle1 } from "../assets/img/workwithus/svg";
import videoSrc from '../assets/video/wwu-vid.mp4';
import { gsap } from 'gsap';
import HorizontalScroll from "../components/HorizontalScroll";
// import Hover from "../components/Hover";
import utils from "../utils/utils";
import JellyEffect from "../utils/jelly";
import WWUS5 from "./WWUS5";
import { ClipLoader } from "react-spinners";
import SignUp from "../components/SignUp";

const shapeStyles = {
	shape1: {
		background: 'rgb(214,47,26)',
		width: '650px',
		height: '650px',
		margin: '-325px 0 0 -325px',
		borderRadius: '50%',
		"position": 'absolute',
	},
	shape2: {
		background: '#C4DFFF',
		width: '440px',
		height: '440px',
		margin: '-220px 0 0 -220px',
		borderRadius: '50%',
		"position": 'absolute',
	},
	shape3: {
		background: '#FF7FE3',
		width: '270px',
		height: '270px',
		margin: '-135px 0 0 -135px',
		borderRadius: '50%',
		"position": 'absolute',
	}
};

const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

export default function WorkWithUs() {
	const wholeRef = useRef(null);
	const hoverRef = useRef(null);
	const contentRef = useRef(null);
	const particlesRef1 = useRef([]);
	const particlesRef2 = useRef([]);
	const particlesContainer = useRef(null);
	const customCursorRef = useRef(null);
	const section1ImagesRef = useRef(null);

	const [signUpModalOpen, toggleSignUpModal] = useState(false)
	const [contactModalOpen, toggleContactModal] = useState(false);
	const [animationState, setAnimationState] = useState("initial");

	useEffect(() => {

		const contentRect = particlesContainer.current.getBoundingClientRect();
		const containerWidth = contentRect.width;
		particlesRef1.current.forEach((particle, index) => {
			gsap.fromTo(
				particle,
				{
					y: '100%',
					x: `${Math.random() * containerWidth}px`, // Random initial x position
					opacity: 0.3,
				},
				{
					y: '-1000px',
					opacity: 1,
					duration: 5 + index * 0.5,
					repeat: -1,
					delay: index * 0.2,
					ease: 'none',
				}
			);
		});
		particlesRef2.current.forEach((particle, index) => {
			gsap.fromTo(
				particle,
				{
					y: '100%',
					x: `${Math.random() * containerWidth}px`, // Random initial x position
					opacity: 0.3,
				},
				{
					y: '-1000px',
					opacity: 1,
					duration: 5 + index * 0.5,
					repeat: -1,
					delay: index * 0.2,
					ease: 'none',
				}
			);
		});
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setAnimationState("in");
				} else if (animationState === "in") {
					setAnimationState("out");
				}
			},
			{
				threshold: 1, // Adjust this threshold to determine when the animation should trigger
			}
		);

		if (section1ImagesRef.current) {
			observer.observe(section1ImagesRef.current);
		}

		return () => {
			if (section1ImagesRef.current) {
				observer.unobserve(section1ImagesRef.current);
			}
		};
	}, [section1ImagesRef, animationState]);


	useEffect(() => {
		if (isTouchDevice()) {
			return;
		}
		const jellyEffect = new JellyEffect();
		return () => {
			jellyEffect.destroy();
		};
	}, [])


	function toggleCustomCursor(state) {
		if (!customCursorRef || !customCursorRef.current) return;
		if (state) {
			customCursorRef.current.classList.add('hover__cursor');
		} else {
			customCursorRef.current.classList.remove('hover__cursor');
		}
	}


	useLayoutEffect(() => {
		if (isTouchDevice()) {
			return;
		}
		const cursor = document.querySelector('.hover__cursor');
		const shapes = document.querySelectorAll('.hover__shape');

		if (!cursor || !shapes.length) {
			console.error("Cursor or shapes not found");
			return;
		}

		const contentRect = contentRef.current.getBoundingClientRect();
		const offsetX = -contentRect.x;
		const offsetY = -contentRect.y;

		const handleMouseMove = (event) => {

			const mouseX = event.clientX + offsetX;
			const mouseY = event.clientY + offsetY;

			gsap.to(cursor, {
				x: mouseX,
				y: mouseY,
				duration: 0.2,
			});

			gsap.to(shapes, {
				x: mouseX,
				y: mouseY,
				stagger: -0.1,
				duration: 0.2,
			});
		};

		const handleMouseEnter = () => {
			gsap.to(shapes, {
				autoAlpha: 1,
				duration: 0.5,
			});
		};

		const handleMouseLeave = () => {
			gsap.to(shapes, {
				autoAlpha: 0,
				duration: 0.5,
			});
		};

		if (hoverRef.current) {
			document.body.addEventListener('mousemove', handleMouseMove);
			hoverRef.current.addEventListener('mouseenter', handleMouseEnter);
			hoverRef.current.addEventListener('mouseleave', handleMouseLeave);
		}

		return () => {
			if (hoverRef.current) {
				document.body.removeEventListener('mousemove', handleMouseMove);
				hoverRef.current.removeEventListener('mouseenter', handleMouseEnter);
				hoverRef.current.removeEventListener('mouseleave', handleMouseLeave);
			}
		};
	}, [hoverRef]);


	return (
		<div className="wwu__nocursor" ref={wholeRef} >
			<Navbar toggleCursor={toggleCustomCursor} />
			<div className="wwu">
				<section className="wwu__section1 " >
					<div className="particles-container" ref={particlesContainer}>

						{[...Array(3)].map((_, index) => (
							<Particle1
								key={index}
								className="particle1"
								ref={(el) => (particlesRef1.current[index] = el)}
							/>
						))}
						{[...Array(3)].map((_, index) => (
							<div
								key={index}
								className="particle"
								ref={(el) => (particlesRef2.current[index] = el)}
							/>
						))}
					</div>

					<div className="wwu__section1--content hover" ref={hoverRef}>
						{!isTouchDevice() &&
							<>
								<div className="hover__cursor" ref={customCursorRef}></div>
								<div className="hover__shapes">
									<div
										className="hover__shape shape-1"
										style={shapeStyles.shape1}
									></div>
									<div
										className="hover__shape shape-2"
										style={shapeStyles.shape2}
									></div>
									<div
										className="hover__shape shape-3"
										style={shapeStyles.shape3}
									></div>
								</div>
							</>
						}

						<div className={isTouchDevice() ? "hover__content--mobile" : "hover__content"} ref={contentRef}>

							<div className="wwu__h1--container" >
								<h1 className="wwu__h1 wwu__h1--hover">
									The start of something
									<span className="wwu__h1--ko wwu__h1--ko--hover">&nbsp;Slick</span>
								</h1>
							</div>
						</div>
					</div>

					<div className="wwu__section1--cta">
						<p className="u-mgn-btm-md">Your <span>free</span> box office.</p>
						<Link to="/launchevent">
							<Button ko={true} >Launch Event</Button>
						</Link>
					</div>
					{/* </Hover> */}
					<div className="wwu__section1--imgs">
						<div className="wwu__section1--imgs-container">
							{/* <img src={phoneImage} alt="mobile phone" className="wwu__section1--phone" />
							<img src={phoneShadowImage} alt="mobile phone shadow" className="wwu__section1--shadow" /> */}
							{/* <video autoPlay={true} loop={true} muted={true} playsInline={true} className="wwu__section1--vid">
								<source src={videoSrc} type="video/mp4" />
								Your browser does not support the video tag.
							</video> */}
							<img src={MobileImage} alt="cell phone" className="wwu__section1--mobile" />
							<TicketIcon className={`wwu__section1--ticket hover__ticket ${animationState === "initial" ? "" : animationState === "in" ? "wwu__ticket-animation--in" : "wwu__ticket-animation--out"}`} />
						</div>
					</div>

				</section>


				<section className="wwu__section2" >
					<div ref={section1ImagesRef} id="sec1ImgRef"></div>
					<div className="wwu__section2-text">
						<HorizontalScroll numItems={5}>

							<div className="wwu__section2-text-box wwu__section2-text-box--first">
								<p>
									Sell tickets to any event for free
								</p>
							</div>
							<div className="wwu__section2-text-box">
								<p>
									Lowest fees on the market for your attendees
								</p>
							</div>
							<div className="wwu__section2-text-box">
								<p>

									Thanks to Stripe your earnings are secured and paid daily
								</p>
							</div>
							<div className="wwu__section2-text-box">
								<p>
									Full-time customer service
								</p>
							</div>
							<div className="wwu__section2-text-box">
								<p>
									Keep 100% of your ticket sales
								</p>
							</div>
							<div className="wwu__section2-text-box wwu__section2-text-box--last">
								<p>
									Detailed analytics and reporting
								</p>
							</div>
						</HorizontalScroll>
					</div>

				</section>



				<div data-cuberto-jelly>
					<section className="wwu__section3">
						<StripePhoneIcon className="wwu__section3--img" />
						<div className="wwu__section3--text">
							<h2 className="wwu__h2 u-mgn-btm-sm">Get Paid Instantly</h2>
							<p className="wwu__p ">Your revenue will be automatically transferred to your bank account.</p>
							<Link to="/launchevent">
							<Button ko={true} >Start Now</Button>
						</Link>
						</div>
					</section>

					<section className="wwu__section4">
						<SalesIcon className="wwu__section4--img" />
						<div className="wwu__section4--text">
							<h2 className="wwu__h2 wwu__section4--heading u-mgn-btm-sm">Track Your Sales in Real Time</h2>
							<p className="wwu__p">Check the evolution of your sales and key customer data in real time with our dynamic dashboard.</p>
							<Link to="/launchevent">
							<Button ko={true} >Start Now</Button>
						</Link>
						</div>
						<BarsIcon className="wwu__section4--bars" />
					</section>

					<section className="wwu__section5">
						<WWUS5 />
					</section>

					<section className="wwu__section6">
						<div className="wwu__section6--text">
							<h2 className="wwu__h2 u-mgn-btm-sm wwu__section6--heading">Quick and Easy Ticket Scanning</h2>
							<p className="wwu__p ">The SlickScan app is quick and turns every phone or tablet into the fastest scanner around!</p>
							<Link to="/willcall">
								<Button ko={true} >Download App</Button>
							</Link>
						</div>
						<ScanIcon className="wwu__section6--img" />
					</section>

					<section className="wwu__section7">
						<SeatsIcon className="wwu__section7--img" />
						<div className="wwu__section7--text">
							<h2 className="wwu__h2 u-mgn-btm-sm">Custom Reserved Seating</h2>
							<p className="wwu__p ">We design a floor plan tailored specifically to your needs. We make reserved seating easy for you.</p>
							<Button ko={true} buttonProps={{ onClick: () => toggleContactModal(true) }}>Contact Us</Button>
						</div>
					</section>
					<section className="wwu__section8">
						<h1 className="wwu__h1--ko u-mgn-btm-md">Get Started Now</h1>
						<Link to="/launchevent">
							<Button ko={true} >Launch Event</Button>
						</Link>
						<div className="wwu__section8--textbox">
							<h1 className="wwu__section8--quote">“</h1>
							<p>
								As a business owner, &nbsp;<span>SLICKTIX</span> has been amazing for us. We didn't have to pay a penny for this service and our customers paid less in fees than they would with any other provider. The ticketing process is worry-free. Highly recommended for the prices and ease-of-use!
							</p>
							<p>
								<span>Ken P., CEO City Ballet San Francisco</span>
							</p>
						</div>
						<div className="wwu__section8--stripe">
							<div>
								Powered by
							</div>
							<img src={StripeImage} alt="Stripe logo" />
						</div>


					</section>
				</div>
			</div>
			<div >
				<Footer />
			</div>

			<Modal isOpen={signUpModalOpen} toggle={() => toggleSignUpModal(!signUpModalOpen)} onClosed={() => toggleCustomCursor(true)} onOpened={() => toggleCustomCursor(false)}>
				<ModalHeader>
					Sign Up
					<div className="modal__close" onClick={() => toggleSignUpModal(false)}>&#x2715;</div>
				</ModalHeader>
				<ModalBody>
					<SignUp toggleModal={toggleSignUpModal} />
				</ModalBody>
			</Modal>

			<Modal isOpen={contactModalOpen} toggle={() => toggleContactModal(!contactModalOpen)} onClosed={() => toggleCustomCursor(true)} onOpened={() => toggleCustomCursor(false)}>
				<ModalHeader>
					Contact
					<div className="modal__close" onClick={() => toggleContactModal(false)}>&#x2715;</div>
				</ModalHeader>
				<ModalBody>
					<div className="u-pad-md">
						<p className="p__1">For all inquiries, please reach out to us at info@slick-tix.org.</p>
					</div>
				</ModalBody>
			</Modal>
		</div>
	)
}
