import React from 'react'
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import './App.css';
import { AuthProvider } from './utils/authContext';
import Landing from "./pages/Landing";
import Event from "./pages/Event";
import ShowRunner from './pages/ShowRunner';
import WorkWithUs from './pages/WorkWithUs';
import ResetPassword from './pages/ResetPassword';
// import Maintenance from './pages/Maintenance';
import WillCall from './pages/WillCall';
import TermsOfService from './pages/ToS';
import Floorplan from './pages/newfloorplan/Floorplan';
import LaunchEvent from './pages/LaunchEvent';
import SignUpComplete from './pages/SignUpComplete';

function App() {
  return (
    <div >
      <AuthProvider>
        <ToastContainer />
        <Switch>
          <Route exact path="/" component={WorkWithUs} />
          <Route path="/launchevent" component={LaunchEvent} />
          <Route exact path="/events" component={Landing} />
          <Route path="/event/:showID" component={Event} />
          <Route path="/showrunner" component={ShowRunner} />
          <Route path="/willcall" component={WillCall} />
          <Route path="/signupcomplete" component={SignUpComplete} />
          <Route path="/workwithus" component={WorkWithUs} />
          <Route path="/tos" component={TermsOfService} />
          <Route path="/reset-password/:uidb64/:token" component={ResetPassword} />
          <Route path="/floorplan" component={Floorplan} />

        </Switch>
      </AuthProvider>
    </div>
  );
}

export default App;
