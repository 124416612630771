import React, { Component } from "react"

export default function Button({buttonProps, ko=false, disabled=false, sm=false, children, brand=false, alt=false, className, action=false}) {
    let buttonClass = "button"
    if (ko) {
        buttonClass += " button--ko"
    }

    if (disabled) {
        buttonClass += " button--disabled"
    }

    if (sm) {
        buttonClass += " button--sm"
    }

    if (brand) {
        buttonClass += " button--brand"
    }

    if (action) {
        buttonClass += " button--action"
    }

    if (alt) {
        buttonClass += " button--alt"
    }

    return (
        <button {...buttonProps} className={`${buttonClass} ${className ? className : ""}`}>
            {children}
        </button>
    )
}
