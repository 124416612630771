import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import Button from './buttons/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import utils from '../utils/utils';
import { CalendarIcon, LoacationIcon } from '../assets/img/event/svg';

export default function LandingCarousel({ shows, ...args }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === shows.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? shows.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // Limit the number of shows to 10
  const limitedShows = shows.slice(0, 10);
  console.log("limitedShows", limitedShows)
  const slides = limitedShows.map((show) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={show.id}
      >
        <div className="landing__carousel-item">
          <div className="landing__carousel-item__bg" style={{ background: `url(${show.production.poster_portrait})` }}></div>
          <div className="landing__carousel-item__poster" style={{backgroundImage: `url(${show.production.poster_portrait})`}}></div>
          <div className="landing__carousel-item__content">
            <h3>{show.production.name}</h3>
            <p>
              <b>
                {show.venue}
              </b>
            </p>
            <p>
              <LoacationIcon className="" />
              {show.location}
            </p>
            <p>
              <CalendarIcon className="" />
              {utils.formatDateTime(show.date)[0]} at {utils.formatDateTime(show.date)[1]}
            </p>
            <Link to={`/event/${show.id}`}>
              <Button>Get Tickets</Button>
            </Link>
          </div>
          {/* <img src={show.production.poster_portrait} alt={show.production.name} className="landing__carousel-item__poster" /> */}
          {/* <CarouselCaption
          captionText={show.caption || show.location}
          captionHeader={show.title}
          /> */}
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
    >
      <CarouselIndicators
        items={limitedShows}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}