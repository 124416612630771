import React, { useEffect, useState, memo, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Sector from './Sector';
import palaceNew from "../FloorPlans/PalaceOfFineArts/data/palaceNew.js";
import chorushallnew from '../FloorPlans/ChorusHall/data/chorushallnew.js';
import API from '../../utils/API.js';
import Section from './Section';
import ClipLoader from "react-spinners/ClipLoader";
import Button from '../../components/buttons/Button.jsx';
import { faPlus, faMinus, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default memo(function FloorPlan({ eventId, seatClick, selectedSeats, theater, isEventManager }) {

    const [sectors, setSectors] = useState([]);
    const [data, setData] = useState({});
    const [selectedSection, setSelectedSection] = useState(null);
    const [initialScale, setInitialScale] = useState(0.2);
    const [wasScaled, setWasScaled] = useState(false);
    const [wrapperKey, setWrapperKey] = useState(0);
    const [loading, setLoading] = useState(true); // Added loading state
    const floorplanRef = useRef(null);
    const [smallScreen, setSmallScreen] = useState(false);
    // let scaleWasSet = false;

    useEffect(() => {
        if (Object.keys(data).length > 0) return;
        API.getEventTix(eventId)
            .then(res => {
                const { tickets } = res.data;
                setSectors(theater.floorplan.sectors);
                setData(tickets);
                // setLoading(false); // Hide loading spinner when data is loaded
            });
    }, [eventId]);

    useEffect(() => {
        const updateInitialScale = () => {
            if (floorplanRef.current) {
                const floorplanWidth = floorplanRef.current.scrollWidth;
                const viewportWidth = window.innerWidth;

                let scale = (viewportWidth * 0.7) / floorplanWidth;
                console.log("initial scale before adjustment", scale);

                const maxScale = 1;

                if (scale > maxScale) {
                    scale = maxScale;
                    floorplanRef.current.style.width = `${viewportWidth * 0.7}px`;
                } else {
                    floorplanRef.current.style.width = 'fit-content';
                }

                console.log("adjusted scale", scale);
                setInitialScale(scale);
                setWasScaled(true);
                // setLoading(false);
                // scaleWasSet = true
                setWrapperKey(prevKey => prevKey + 1); // Force re-initialization by changing key
            }
        };

        updateInitialScale();
        window.addEventListener('resize', updateInitialScale);

        return () => {
            window.removeEventListener('resize', updateInitialScale);
        };
    }, [sectors]);

      useEffect(() => {
        if (wasScaled && Object.keys(data).length > 0) {
            setLoading(false);
        }
      }, [initialScale, wasScaled, data])

    useEffect(() => {

        if (window.innerWidth < 600) {
            setSmallScreen(true);
        } else {
            setSmallScreen(false);
        }
    }, [window.innerWidth]);

    const handleSectionClick = section => {
        setSelectedSection(section);
    };

    const handleBackClick = () => {
        setSelectedSection(null);
    };

    return (
        <div className="floorplan__container">
            {loading &&
            <>
            <p className="p__2 u-align-center">Fetching latest ticket data...</p>
                <div className="floorplan__loading">
                    <ClipLoader className="floorplan__loading__spinner" color="#0029FF" loading={true} size={150} speedMultiplier={0.3} />
                </div>
            </>
             }
            <>
                {selectedSection ? (
                    <div className={`floorplan__section ${smallScreen ? "floorplan__section--mobile" : ""} `}>
                        <div className="u-mgn-btm-md floorplan__section__back">

                            <Button sm={true} buttonProps={{ onClick: handleBackClick }} >Back</Button>
                        </div>

                        <Section
                            section={selectedSection}
                            data={data}
                            seatClick={seatClick}
                            selectedSeats={selectedSeats}
                            isEventManager={isEventManager}
                        />
                    </div>
                ) : (
                    <TransformWrapper
                        key={wrapperKey} // Use key to re-initialize
                        initialScale={initialScale}
                        minScale={0.1}
                        maxScale={2}
                        centerOnInit
                        doubleClick={{ disabled: true }}
                        // onInit={() => setLoading(false)} // Hide loading spinner after zoom initialization
                        className="floorplan__transform__wrapper"
                    >
                        {({ zoomIn, zoomOut, resetTransform, centerView }) => (
                            <div className="floorplan">
                                <div className="floorplan__zoom__tools">
                                    <Button sm={true} buttonProps={{ onClick: () => zoomIn(), style: { backgroundColor: "#EDEDED", color: "black" } }} ><FontAwesomeIcon icon={faPlus} /></Button>
                                    <Button sm={true} buttonProps={{ onClick: () => zoomOut(), style: { backgroundColor: "#EDEDED", color: "black" } }} ><FontAwesomeIcon icon={faMinus} /></Button>
                                    <Button sm={true} buttonProps={{ onClick: () => resetTransform(), style: { backgroundColor: "#EDEDED", color: "black" } }} ><FontAwesomeIcon icon={faRedo} /></Button>
                                </div>
                                <TransformComponent className="floorplan__transform">
                                    <div ref={floorplanRef} style={{ width: 'fit-content', margin: '0 auto' }}>
                                        {sectors.map((sector, index) => (
                                            <Sector
                                                key={index}
                                                sector={sector}
                                                data={data}
                                                onSectionClick={handleSectionClick}
                                                seatClick={seatClick}
                                                selectedSeats={selectedSeats}
                                            />
                                        ))}
                                    </div>
                                </TransformComponent>
                            </div>
                        )}
                    </TransformWrapper>
                )}
            </>
            {/* )} */}
        </div>
    );
});