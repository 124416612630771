import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import Input from "../../components/Input";
import Button from "../../components/buttons/Button";
import { useAuth } from '../../utils/authContext';
import utils from "../../utils/utils";

export default function Profile() {
  const { showrunner, auth } = useAuth();

  const [profile, setProfile] = useState({
    company: "",
    email: "",
    first_name: "",
    last_name: ""
  });

  const [notificationPreference, setNotificationPreference] = useState("every_order");

  useEffect(() => {
    if (!auth || !showrunner) return;

    const { company, email, first_name, last_name } = showrunner;
    setProfile({ company, email, first_name, last_name });

    // Fetch the notification preferences from the API
    API.getTicketSalesNotificationPreferences(showrunner.id)
      .then(res => {
        const { ticket_sales_email } = res.data;
        setNotificationPreference(ticket_sales_email);
      })
      .catch(err => {
        console.error("Error fetching notification preferences", err);
        utils.sendAlert("Error fetching notification preferences", "error");
      });
  }, [auth, showrunner]);

  function handleChange(e) {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
  }

  function handleNotificationPreferenceChange(e) {
    const { value } = e.target;
    setNotificationPreference(value);

    // Make the API call to update notification preferences
    API.updateTicketSalesNotificationPreferences(showrunner.id, value)
      .then(() => {
        utils.sendAlert("Notification preferences updated", "success");
      })
      .catch(() => {
        utils.sendAlert("Error updating notification preferences", "error");
      });
  }

  function updateProfile() {
    API.updateProfile(showrunner.id, { ...profile })
      .then(() => {
        utils.sendAlert("Profile Updated", "success");
      })
      .catch(() => {
        utils.sendAlert("Error updating profile. Contact us at info@slick-tix.org if the issue persists.", "error");
      });
  }

  return (
    <div className="profile">
      <h1>Profile Settings</h1>

      <div>
        <Input inputProps={{ onChange: handleChange, value: profile.first_name, name: "first_name" }} label="First Name" />
      </div>

      <div>
        <Input inputProps={{ onChange: handleChange, value: profile.last_name, name: "last_name" }} label="Last Name" />
      </div>

      <div>
        <Input inputProps={{ onChange: handleChange, value: profile.email, name: "email" }} label="Email - Your customers will be able to reach out to you at this email address." />
      </div>

      <div>
        <Input inputProps={{ onChange: handleChange, value: profile.company, name: "company" }} label="Company (optional - will display on event pages if name is provided)." />
      </div>

      <p className="p__1 u-mgn-btm-sm">Ticket Sales Notification Preferences</p>
      <div className="input__radio">
        <input
          type="radio"
          id="every_order"
          name="notificationPreference"
          value="every_order"
          checked={notificationPreference === "every_order"}
          onChange={handleNotificationPreferenceChange}
        />
        <label className="input__label u-mgn-left-sm" htmlFor="every_order">
          Email for every ticket order
        </label>
      </div>

      <div className="input__radio">
        <input
          type="radio"
          id="daily_summary"
          name="notificationPreference"
          value="daily_summary"
          checked={notificationPreference === "daily_summary"}
          onChange={handleNotificationPreferenceChange}
        />
        <label className="input__label u-mgn-left-sm" htmlFor="daily_summary">
          Daily summary of ticket orders
        </label>
      </div>

      <div className="input__radio">
        <input
          type="radio"
          id="weekly_summary"
          name="notificationPreference"
          value="weekly_summary"
          checked={notificationPreference === "weekly_summary"}
          onChange={handleNotificationPreferenceChange}
        />
        <label className="input__label u-mgn-left-sm" htmlFor="weekly_summary">
          Weekly summary of ticket orders
        </label>
      </div>

      <div className="input__radio">
        <input
          type="radio"
          id="none"
          name="notificationPreference"
          value="none"
          checked={notificationPreference === "none"}
          onChange={handleNotificationPreferenceChange}
        />
        <label className="input__label u-mgn-left-sm" htmlFor="none">
          No emails
        </label>
      </div>

      <Button buttonProps={{ onClick: updateProfile }}>Update Profile</Button>
    </div>
  );
}