import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default {
    formatDateTime: str => {
        if (!str) return ["", ""]
        const weekDay = new Date(str).toString().split(" ")[0]
        let dateTime
        if (str.includes("T")) {
            dateTime = str.split("T")
        }
        else {
            dateTime = str.split(" ")
        }
        let date = dateTime[0]
        date = date.split("-")
        date = date[1] + "/" + date[2] + "/" + date[0]
        let time = dateTime[1].split(":")
        let hour = parseInt(time[0])
        let timeOfDay = ""
        if (hour - 12 > 0) {
            timeOfDay = "PM"
            hour = hour - 12
        } else {
            timeOfDay = "AM"
        }

        const minutes = time[1]
        time = hour + ":" + minutes
        // dateTime = weekDay + " " + date + " | " + time + timeOfDay
        dateTime = [`${weekDay} ${date}`, `${time} ${timeOfDay}`]
        return dateTime
    },
    formatDateTimeLocal: (datetime) => {
        if (!datetime) return new Date().toISOString().slice(0, 16)
        return datetime.slice(0, 16);
        const date = new Date(datetime);
        const offset = date.getTimezoneOffset();
        const localDate = new Date(date.getTime() - (offset * 60 * 1000));
        return localDate.toISOString().slice(0, 16);
    },
    formatFinancial: num => {
        try {
            var p = num.toFixed(2).split(".");
            return "$" + p[0].split("").reverse().reduce(function (acc, num, i, orig) {
                return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
            }, "") + "." + p[1];
        } catch {
            return "$ 0"
        }
    },
    queryParamsToObj: url => JSON.parse('{"' + decodeURI(url).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'),
    buildQueryString: params => {
        return Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    },
    sendAlert: (msg, type) => {
        return toast(msg, {
            position: "top-center",
            className: "alert-toast",
            progressClassName: `alert-toast__${type}`,
            autoClose: false
        });
    },
    calculateTicketFees: (bof, ticketPrice) => {
        let total = ticketPrice * bof
        total = Math.round(total * 100) / 100
        return total
        // let total = ticketPrice + bofTotal
        // return total
        // const stripFee = total * .029 + .3
        // total = bofTotal + stripFee
        // total = Math.round(total * 100) / 100;
    },
    calculateStripeFee: (desiredTotal) => {

        const percentFee = .029
        const fixedFee = .3
        const multiplier = 1000
        const y = desiredTotal * multiplier

        const percentComponent = (percentFee) * multiplier
        const baseComponent = fixedFee * multiplier
        const coefficient = multiplier - percentComponent

        const totalCharge = (y + baseComponent) / coefficient
        return totalCharge - desiredTotal

    },
    
}
