import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import ShowCard from "../../components/cards/ShowCard";
import 'react-quill/dist/quill.snow.css';
import { Link } from "react-router-dom";
import { useAuth } from '../../utils/authContext';
import utils from "../../utils/utils";

export default function Events(props) {
    const { showrunner, fetchAuth } = useAuth();
    const [events, setEvents] = useState([]);
    const [activeEvents, setActiveEvents] = useState([]);
    const [finishedEvents, setFinishedEvents] = useState([]);

    useEffect(() => {
        if (!showrunner) return;

        // Check if Stripe onboarding is complete
        if (showrunner.stripe_connect_id && !showrunner.stripe_complete) {
            checkStripeStatus(showrunner, showrunner.stripe_connect_id);
        } else {
            reset();
        }
    }, [showrunner]);

    function reset() {
        if (showrunner.id) {
            const activeParams = API.objectToParams({ showrunner_id: showrunner.id, is_finished: false });
            const finishedParams = API.objectToParams({ showrunner_id: showrunner.id, is_finished: true });
            API.getShows(activeParams)
                .then(res => {
                    console.log("shows", res.data);
                    // setEvents(res.data);
                    setActiveEvents(res.data);
                })
                .catch(err => {
                    console.error("Error fetching shows", err);
                });
            API.getShows(finishedParams)
                .then(res => {
                    console.log("finished shows", res.data);
                    setFinishedEvents(res.data);
                })


        }
    }

    const checkStripeStatus = (showrunner, stripeConnectId) => {
        API.getStripeConnectDash(stripeConnectId)
            .then(res => {
                const { link, error, onboarding } = res.data;
                if (onboarding.status === "complete") {
                    // utils.sendAlert("Stripe setup completed", "success");

                    // Update the stripe_complete flag in the Showrunner instance
                    API.updateProfile(showrunner.id, { stripe_complete: true })
                        .then(res => {
                            utils.sendAlert("Your Stripe setup is complete!", "success");
                            reset();  // Fetch the events after updating the profile
                            fetchAuth();  // Fetch the updated profile
                            API.notifyAdmin({ subject: "Stripe setup complete", message: `Showrunner ${showrunner.username} has completed Stripe setup` })
                                .catch(err => {
                                    // Log the error for debugging, but don't disrupt the user experience
                                    console.error("Failed to notify admin:", err);
                                });
                        })
                        .catch(err => {
                            console.error("Error updating Showrunner profile", err);
                        });
                } else if (error) {
                }
            })
            .catch(error => {
                console.error("Error checking Stripe status", error);
            });
    };

    function deleteShow(showID) {
        API.deleteShow(showID)
            .then(res => {
                console.log("deleted show", res.data);
                utils.sendAlert("Event deleted", "success");
                reset();
            })
            .catch(err => {
                console.error("Error deleting event", err);
                utils.sendAlert("Error deleting event", "error");
            });
    }

    return (
        <>
            <div className="showrunner__prods">
                <Link to="/showrunner/events/create">
                    <ShowCard createNew={true} addCb={() => console.log("create new event")} />
                </Link>
                {activeEvents.map(ev => (
                    <ShowCard key={ev.id} show={ev} createNew={false} deleteCb={deleteShow} />
                ))}
            </div>
            {activeEvents.length > 1 && finishedEvents.length > 1 && (
                <div className="showrunner__prods__separator"></div>
            )}
            <div className="showrunner__prods">

                {finishedEvents.map(ev => (
                    <ShowCard key={ev.id} show={ev} createNew={false} deleteCb={deleteShow} />
                ))}
            </div>
        </>
    );
}