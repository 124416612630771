import React, { useState, useEffect } from "react";
import Tabular from "../../../components/Tabular";
import Tickets from "./Tickets";
import Orders from '../Orders';
import Clients from "./Clients"; // Adjust the import path according to your project structure

export default function Admin() {

    const tabs = [
        {
            name: "Tickets",
            component: (
                <Tickets
                />
            ),
        },
        {
            name: "Orders",
            component: <Orders isAdmin={true} />,
        },
        {
            name: "Clients",
            component: <Clients />,
        },
    ];

    return (
        <div className="admin">
            <h1>Admin Dashboard</h1>
            <div className="admin__tabs">
                <Tabular tabs={tabs} />
            </div>
        </div>
    );
}