import React, { useState, useEffect } from 'react';
import Input from '../components/Input';
import Button from '../components/buttons/Button';
import ReactQuill from 'react-quill';
import API from '../utils/API';
import ImageUpload from '../components/ImageUpload';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SignUp from '../components/SignUp';

export default function LaunchEvent() {

  const [newProd, setNewProd] = useState({
    name: "",
    portrait_poster: null,
    custom_features: "", // Field for custom feature requests
  });
  const [newProdDesc, setNewProdDesc] = useState("");
  const [newShow, setNewShow] = useState({
    date: "",
    theater: "",
    location: "",
    venue: "",
    private: false, // Field for making the show private
  });
  const [theaters, setTheaters] = useState([]);
  const [signUpModalOpen, toggleSignUpModal] = useState(false);
  const [floorplanRequest, setFloorplanRequest] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const history = useHistory();

  useEffect(() => {
    API.getTheaters().then(res => {
      console.log("Theaters", res.data);
      setTheaters(res.data);
    });
  }, []);

  useEffect(() => {
    // Check if all mandatory fields are filled
    const { name } = newProd;
    const { date, venue, location } = newShow;
    if (name && date && venue && location) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [newProd, newShow]);

  function handleNewShowChange(e) {
    const { name, value, checked } = e.target;
    if (name === "private") {
      setNewShow(prev => ({ ...prev, [name]: checked }));
    } else {
      setNewShow(prev => ({ ...prev, [name]: value }));
    }
  }

  function handleNewProdChange(e) {
    const { name, type, value, files } = e.target;
    if (!e.target) return;
    if (type === "file") {
      setNewProd(prev => ({ ...prev, [name]: files[0] }));
    } else {
      setNewProd(prev => ({ ...prev, [name]: value }));
    }
  }

  const eventData = {
    name: newProd.name,
    description: newProdDesc,
    portrait_poster: newProd.portrait_poster,
    // custom_features: newProd.custom_features,
    date: newShow.date,
    theater: newShow.theater,
    location: newShow.location,
    venue: newShow.venue,
    private: newShow.private,
    floorplanRequest
  };

  return (
    <>
      <Navbar hideSignUp={true}/>
      <div className="launch-event">
        <h1 className="launch-event__heading">Create Your Event</h1>
        <div className="launch-event__form">
          <div className="launch-event__form--left">
            <Input
              inputProps={{
                type: "text",
                placeholder: "Production name",
                name: "name",
                onChange: handleNewProdChange,
                required: true,
              }}
              required={true}
              label="Event Title"
            />
            <Input
              inputProps={{
                type: "text",
                placeholder: "Venue",
                name: "venue",
                onChange: handleNewShowChange,
              }}
              required={true}
              label="Venue"
            />
            <Input
              inputProps={{
                type: "text",
                placeholder: "Address",
                name: "location",
                onChange: handleNewShowChange,
              }}
              required={true}
              label="Address"
            />
            <Input
              inputProps={{
                type: "datetime-local",
                name: "date",
                onChange: handleNewShowChange,
              }}
              required={true}
              label="Date and Time"
            />
            <div className="input__label">Description</div>
            <ReactQuill
              theme="snow"
              value={newProdDesc}
              name="description"
              onChange={setNewProdDesc}
            />
            <div className="u-mgn-vert-md">
              {!floorplanRequest ? (
                <>
                  <label className="input__label" htmlFor="theater">Floorplan (leave blank if none) <span className='link--sm' onClick={() => setFloorplanRequest(true)}>Don't see your floorplan? Click here and we'll make it for free!</span></label>
                  <div>
                    <select
                      className="input ch-p1"
                      name="theater"
                      onChange={handleNewShowChange}
                    >
                      <option>None</option>
                      {theaters.map(theater => (
                        <option key={theater.id} value={theater.name}>
                          {theater.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>) : (
                <>
                  <p className="p__2">
                    Your floorplan request will be submitted once you save your event.
                  </p>
                  <Button sm={true} buttonProps={{ onClick: () => setFloorplanRequest(false) }}>Undo</Button>
                </>
              )
              }
            </div>
            <div className="u-mgn-vert-sm launch-event__private__group">
              <input
                type="checkbox"
                name="private"
                className='input__checkbox u-mgn-right-sm'
                onChange={handleNewShowChange}
              />
              <label className="input__label" htmlFor="private">Make this event private</label>
            </div>
            {/* <div className="u-mgn-vert-md">
              <label className="input__label" htmlFor="custom_features">Custom Features</label>
              <textarea
                className="input launch-event__custom-features"
                name="custom_features"
                placeholder="Don't see what you need? Let us know! We will get back to you shortly to make sure your event is a success."
                onChange={handleNewProdChange}
              />
            </div> */}
          </div>
          <div className="launch-event__form--right">
            <div className="launch-event__img-upload">
              <ImageUpload
                label="Poster"
                subLabel="(Portrait orientation recommended)"
                inputProps={{
                  type: "file",
                  name: "portrait_poster",
                  onChange: handleNewProdChange,
                }}
              />
            </div>
            <Button
              className="launch-event__submit"
              buttonProps={{ onClick: () => {formIsValid && toggleSignUpModal(true)} }}
              disabled={!formIsValid} // Disable if the form is not valid
            >
              Save Event
            </Button>
          </div>
        </div>
      </div>
      <Footer />

      {/* Modal for Sign-Up */}
      <Modal isOpen={signUpModalOpen} toggle={() => toggleSignUpModal(!signUpModalOpen)}>
        <ModalHeader>
          Sign Up to Launch Your Event
          <div className="modal__close" onClick={() => toggleSignUpModal(false)}>
            &#x2715;
          </div>
        </ModalHeader>
        <ModalBody>
          <SignUp toggleModal={toggleSignUpModal} eventData={eventData} />
        </ModalBody>
      </Modal>
    </>
  );
}