import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Particle1 } from "../assets/img/workwithus/svg"; // Assuming Particle1 is an SVG

export default function SignUpComplete() {
  const particlesContainer = useRef(null);
  const particlesRef1 = useRef([]);
  const particlesRef2 = useRef([]);

  useEffect(() => {
    const contentRect = particlesContainer.current.getBoundingClientRect();
    const containerWidth = contentRect.width;
    const containerHeight = contentRect.height;
    const middleY = containerHeight / 2; // Set middle of container as origin

    particlesRef1.current.forEach((particle, index) => {
      gsap.fromTo(
        particle,
        {
          y: middleY + 300, // Start at middle of container (origin point)
          x: containerWidth / 2, // Start from center horizontally
          opacity: 1, // Start fully visible
          scale: 0.7, // Start smaller
          rotation: Math.random() * 360, // Add random rotation
        },
        {
          y: middleY - 300 - Math.random() * 100, // Sprout upward and randomize height a bit
          x: `${Math.random() * 800}px`, // Move outward in random x direction
          opacity: 0, // Fade out as the particles fall
          scale: 1, // Grow slightly as they sprout up
          duration: 2 + index * 0.2, // Control speed of upward movement
          ease: "power2.out", // Easing to make it start fast and slow down as it rises
          repeat: -1, // Infinite loop
          delay: index * 0.1, // Stagger particle animations
          yoyo: false, // Don't go back to the original position
        }
      );
    });

    particlesRef2.current.forEach((particle, index) => {
      gsap.fromTo(
        particle,
        {
          y: middleY + 300, // Start at middle
          x: containerWidth / 2,
          opacity: 1,
          scale: 0.7,
          rotation: Math.random() * 360,
        },
        {
          y: middleY - 300 - Math.random() * 100, // Sprout upward
          x: `${Math.random() * 800}px`,
          opacity: 0, // Fade out as it falls
          scale: 1,
          duration: 2 + index * 0.2,
          ease: "power2.out", // Similar easing for smoother rise and fall
          repeat: -1,
          delay: index * 0.15, // Stagger slightly differently than the first group
          yoyo: false, // No return to original position
        }
      );
    });
  }, []);

  return (
    <>
      <div className="signup-complete">
        <h1 className="signup-complete__heading">Welcome to SlickTix</h1>
        <p className="signup-complete__text">
          Please verify your email. You should receive a link that will complete your sign up and log you in!
        </p>

        <div className="signup-complete__particles-container" ref={particlesContainer}>
          {[...Array(3)].map((_, index) => (
            <Particle1
              key={index}
              className="signup-complete__particle signup-complete__particle--type1"
              ref={(el) => (particlesRef1.current[index] = el)}
            />
          ))}
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="signup-complete__particle signup-complete__particle--type2"
              ref={(el) => (particlesRef2.current[index] = el)}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}