import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/img/SVG/st-logo-small-white.svg";
import { ReactComponent as LogoBlack } from "../assets/img/SVG/st-logo-small-black.svg";

export default function Sidenav(props) {
  const [active, setActive] = useState(props.activeTab);
  const [isOpen, setIsOpen] = useState(true);
  const [tabs, setTabs] = useState(props.items);

  const toggleSidenav = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setTabs(props.items);
    console.log("tabs", tabs);
  }, [props.items]);

  return (
    <>
    {isOpen ? 
      <div className="sidebar__spacer"></div>
      : <div className="sidebar__spacer--sm"></div>}
      <div className={`sidenav__container ${isOpen ? "" : "sidenav__container--closed"}`}>
        <div className={`sidenav__logo-container ${isOpen ? "" : "sidenav__logo-container--closed"}`}>
        {isOpen ?
          <Link to="/" className="sidenav__logo">
            <Logo />
          </Link>
          :
          <Link to="/" className="sidenav__logo--closed">
            <LogoBlack />
          </Link>
        }
        </div>
        <button onClick={toggleSidenav} className={`sidenav__toggle-button ${isOpen ? "" : "sidenav__toggle-button--open"}`}>
          {isOpen ? "Close" : "Open"}
        </button>
        {isOpen ?
          <ul className="sidenav">
            {tabs.map((item) => (
              <li
                key={item.name}
                className={`sidenav__item ${item.urlName === active ? "sidenav__item--active" : ""}`}
                onClick={() => setActive(item.urlName)}
              >
                <Link to={item.link} className="sidenav__link">
                  <div className="sidenav__icon">{item.icon}</div>
                  <span>{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
          : null}
      </div>
    </>
  );
}