import React, { useState, useEffect } from "react";
import API from "../../../utils/API";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer, VictoryBar } from 'victory';
import ClipLoader from "react-spinners/ClipLoader";
import utils from "../../../utils/utils";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import Button from "../../../components/buttons/Button";

const flashyTheme = {
  axis: {
    style: {
      axis: {
        stroke: "white",
        strokeWidth: 2,
      },
      axisLabel: {
        fontSize: 20,
        padding: 30,
        fill: "#0029FF",
      },
      grid: {
        stroke: "none",
      },
      ticks: {
        stroke: "transparent",
        size: 5,
        strokeWidth: 2,
      },
      tickLabels: {
        fontSize: 15,
        padding: 5,
        fill: "#A2A2A2",
      },
    },
  },
  line: {
    style: {
      data: {
        strokeWidth: 3,
        strokeLinecap: "round",
      },
      labels: {
        fontSize: 15,
        fill: "#0029FF",
      },
    },
  },
};

export default function Tickets() {
  const [dataLoading, setDataLoading] = useState(true);
  const [salesChart, setSalesChart] = useState([]);
  const [salesTotal, setSalesTotal] = useState(0);
  const [orderTotalChart, setOrderTotalChart] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [stFeeChart, setStFeeChart] = useState([]);
  const [stFeeTotal, setStFeeTotal] = useState(0);
  const [ccFeeChart, setCcFeeChart] = useState([]);
  const [ccFeeTotal, setCcFeeTotal] = useState(0);
  const [referralFeeChart, setReferralFeeChart] = useState([]);
  const [referralFeeTotal, setReferralFeeTotal] = useState(0);
  const [ticketsChart, setTicketsChart] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);

  const [startDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);
  const [allTime, setAllTime] = useState(false);
  const [selectedShow, setSelectedShow] = useState(0);
  const [selectedClient, setSelectedClient] = useState(0);
  const [shows, setShows] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, selectedShow, selectedClient, allTime]);

  const fetchFilters = async () => {
    try {
      const showResponse = await API.getShows("");
      setShows(showResponse.data);

      const clientResponse = await API.getClients();
      setClients(clientResponse.data);
    } catch (error) {
      console.error("Error fetching filters", error);
    }
  };

  const fetchData = async () => {
    setDataLoading(true);
    console.log("Fetching data with filters", { startDate, endDate, selectedShow, selectedClient, allTime });

    const filters = {};

    if (startDate && endDate && !allTime) {
      filters["start_date"] = startDate.format('YYYY-MM-DD');
      filters["end_date"] = endDate.format('YYYY-MM-DD');
    }

    if (selectedShow > 0) {
      filters["show_id"] = selectedShow;
    }

    if (selectedClient > 0) {
      filters["showrunner_id"] = selectedClient;
    }

    try {
      const response = await API.getGod(filters);
      const { financial_chart, ticket_chart, total_revenue, total_order_value, total_st_fee, total_cc_fee, total_referral_fee, total_tickets } = response.data;

      setSalesChart(financial_chart.map(item => ({ x: new Date(item.date_created__date), y: item.total_revenue })));
      setSalesTotal(total_revenue);
      setOrderTotalChart(financial_chart.map(item => ({ x: new Date(item.date_created__date), y: item.order_total })));
      setOrderTotal(total_order_value);
      setStFeeChart(financial_chart.map(item => ({ x: new Date(item.date_created__date), y: item.st_fee_total })));
      setStFeeTotal(total_st_fee);
      setCcFeeChart(financial_chart.map(item => ({ x: new Date(item.date_created__date), y: item.cc_fee })));
      setCcFeeTotal(total_cc_fee);
      setReferralFeeChart(financial_chart.map(item => ({ x: new Date(item.date_created__date), y: item.referral_fee })));
      setReferralFeeTotal(total_referral_fee);
      setTicketsChart(ticket_chart.map(item => ({ x: new Date(item.order__date_created__date), y: item.tickets_sold })));
      setTicketTotal(total_tickets);

      setDataLoading(false);
    } catch (error) {
      console.error("Error fetching tickets data", error);
      setDataLoading(false);
    }
  };

  function handleShowChange(e) {
    setSelectedShow(parseInt(e.target.value, 10));
  }

  function handleClientChange(e) {
    setSelectedClient(parseInt(e.target.value, 10));
  }

  return (
    <>
      {dataLoading ? (
        <div className="u-flex-all-center u-full-width">
          <ClipLoader color="#0029FF" loading={true} size={150} speedMultiplier={0.3} />
        </div>
      ) : (
        <div className="admin">
          <h1>Tickets Dashboard</h1>
          <div className="u-mgn-vert-sm">
            <Button buttonProps={{ onClick: fetchData }} className="admin__filters--refresh">Refresh</Button>
          </div>
          <div className="admin__filters u-flex-justify-around">
            <div className="admin__filters__section admin__filters__section--f1">
              <select value={selectedClient} onChange={handleClientChange}>
                <option value={0}>All Clients</option>
                {clients.map(client => (
                  <option key={client.id} value={client.id}>{client.username}</option>
                ))}
              </select>
            </div>

            <div className="admin__filters__section admin__filters__section--f2">
              <select value={selectedShow} onChange={handleShowChange}>
                <option value={0}>All Shows</option>
                {shows.map(show => (
                  <option key={show.id} value={show.id}>{show.production.name}, {utils.formatDateTime(show.date)[0]} at {utils.formatDateTime(show.date)[1]}</option>
                ))}
              </select>
            </div>

            <div className="admin__filters__section admin__filters__section--f3">
              {allTime ? (
                <div className="select u-hover-pointer u-flex u-flex-align-center" onClick={() => setAllTime(false)}>
                  <span>All Time</span>
                  <span className="caret"></span>
                </div>
              ) : (
                <div className="date-range">
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="start_date_id"
                    endDate={endDate}
                    endDateId="end_date_id"
                    onDatesChange={({ startDate, endDate }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={setFocusedInput}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    displayFormat="MM/DD/YYYY"
                  />
                  <Button buttonProps={{ onClick: () => setAllTime(true) }} sm={true} className="admin__filters--reset">Reset</Button>
                </div>
              )}
            </div>
          </div>

          <div className="admin__charts">
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Slick Tix Fee Revenue:</p>
              <p className="dash-home__chart__total">{utils.formatFinancial(stFeeTotal)}</p>
              <VictoryChart
                theme={flashyTheme}
                scale={{ x: "time" }}
                containerComponent={<VictoryVoronoiContainer />}
              >
                <VictoryAxis
                  tickFormat={(x) => `${x.getMonth() + 1}/${x.getDate()}`}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={(y) => `$${y}`}
                />
                <VictoryLine
                  data={stFeeChart}
                  x="x"
                  y="y"
                  style={{ data: { stroke: "#00FF00" } }}
                  labels={({ datum }) => `$${datum.y && datum.y.toFixed(2)}`}
                  labelComponent={<VictoryTooltip />}
                />
              </VictoryChart>
            </div>


            {/* Sales Chart */}
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Gross Revenue:</p>
              <p className="dash-home__chart__total">{utils.formatFinancial(salesTotal)}</p>
              <VictoryChart
                theme={flashyTheme}
                scale={{ x: "time" }}
                containerComponent={<VictoryVoronoiContainer />}
              >
                <VictoryAxis
                  tickFormat={(x) => `${x.getMonth() + 1}/${x.getDate()}`}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={(y) => `$${y}`}
                />
                <VictoryLine
                  data={salesChart}
                  x="x"
                  y="y"
                  style={{ data: { stroke: "#B4BCFF" } }}
                  labels={({ datum }) => `$${datum.y && datum.y.toFixed(2)}`}
                  labelComponent={<VictoryTooltip />}
                />
              </VictoryChart>
            </div>

            {/* Order Total Chart */}
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Total Ticket Value:</p>
              <p className="dash-home__chart__total">{utils.formatFinancial(orderTotal)}</p>
              <VictoryChart
                theme={flashyTheme}
                scale={{ x: "time" }}
                containerComponent={<VictoryVoronoiContainer />}
              >
                <VictoryAxis
                  tickFormat={(x) => `${x.getMonth() + 1}/${x.getDate()}`}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={(y) => `$${y}`}
                />
                <VictoryLine
                  data={orderTotalChart}
                  x="x"
                  y="y"
                  style={{ data: { stroke: "#FFD700" } }}
                  labels={({ datum }) => `$${datum.y && datum.y.toFixed(2)}`}
                  labelComponent={<VictoryTooltip />}
                />
              </VictoryChart>
            </div>

            {/* Credit Card Fee Chart */}
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Credit Card Fees:</p>
              <p className="dash-home__chart__total">{utils.formatFinancial(ccFeeTotal)}</p>
              <VictoryChart
                theme={flashyTheme}
                scale={{ x: "time" }}
                containerComponent={<VictoryVoronoiContainer />}
              >
                <VictoryAxis
                  tickFormat={(x) => `${x.getMonth() + 1}/${x.getDate()}`}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={(y) => `$${y}`}
                />
                <VictoryLine
                  data={ccFeeChart}
                  x="x"
                  y="y"
                  style={{ data: { stroke: "#FF6347" } }}
                  labels={({ datum }) => `$${datum.y && datum.y.toFixed(2)}`}
                  labelComponent={<VictoryTooltip />}
                />
              </VictoryChart>
            </div>

            {/* Referral Fee Chart */}
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Referral Fees Paid:</p>
              <p className="dash-home__chart__total">{utils.formatFinancial(referralFeeTotal)}</p>
              <VictoryChart
                theme={flashyTheme}
                scale={{ x: "time" }}
                containerComponent={<VictoryVoronoiContainer />}
              >
                <VictoryAxis
                  tickFormat={(x) => `${x.getMonth() + 1}/${x.getDate()}`}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={(y) => `$${y}`}
                />
                <VictoryLine
                  data={referralFeeChart}
                  x="x"
                  y="y"
                  style={{ data: { stroke: "#4682B4" } }}
                  labels={({ datum }) => `$${datum.y && datum.y.toFixed(2)}`}
                  labelComponent={<VictoryTooltip />}
                />
              </VictoryChart>
            </div>

            {/* Tickets Sold Chart */}
            <div className="dash-home__chart">
              <p className="dash-home__chart__title u-mgn-btm-sm">Tickets Sold:</p>
              <p className="dash-home__chart__total">{ticketTotal}</p>
              <VictoryChart
                theme={flashyTheme}
                scale={{ x: "time" }}
                domainPadding={{ x: 20 }}
                containerComponent={<VictoryVoronoiContainer />}
              >
                <VictoryAxis
                  tickFormat={(x) => `${x.getMonth() + 1}/${x.getDate()}`}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={(y) => `${y}`}
                />
                <VictoryBar
                  data={ticketsChart}
                  x="x"
                  y="y"
                  style={{ data: { fill: "#FDB9EE" } }}
                  labels={({ datum }) => `${datum.y}`}
                  labelComponent={<VictoryTooltip />}
                />
              </VictoryChart>
            </div>
          </div>
        </div>
      )}
    </>
  );
}                  