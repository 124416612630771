import React, { useEffect, useState } from "react";
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function TH(props) {

  const initState = {
    active: false
  }

  const [state, setState] = useState({ active: false })


  return <th onClick={props.onClick && props.onClick} className={`c-table__h ${props.className && props.className}`}>
    <>
      {props.children} {props.sort ? <div className="u-mgn-left-sm" onClick={props.cb}> {state.active ?
        // Won't work if this is an icon too
        <FontAwesomeIcon icon={faCaretUp} className={`c-table__sort`} />
        :
        <FontAwesomeIcon icon={faCaretDown} className={`c-table__sort`} />
      }</div> : <p></p>}
    </>
  </th>;
}
